export default {
  entities: {
    commodes: {},
    tiroirs: {},
    contenus: {},
    proches: {},
    settings: {},
    categories: {}
  },
  loaded: {
    appstorage: false,
    commodes: false,
    tiroirs: false,
    contenus: false,
    proches: false,
    settings: false,
    categories: false
  },
  loading: {
    commodes: false,
    tiroirs: false,
    contenus: false,
    proches: false,
    settings: false,
    categories: false
  }
};
