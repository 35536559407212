import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';

import { useHistory, useLocation } from 'react-router-dom';

import { find, includes } from 'lodash';
import { connect } from 'react-redux';
import { register, updateUser } from '../../store/modules/auth';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

import BaseLayout from '../shared/BaseLayout';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
import {
  cancelSubscription,
  getPrices,
  getSessionStripe,
  getSubscriptionStripe
} from '../../store/modules/stripe';

const AbonnementPage = ({
  register,
  isInscription,
  getPrices,
  user,
  getSessionStripe,
  getSubscriptionStripe,
  cancelSubscription,
  updateUser
}) => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPack, setSelectedPack] = useState(user.paiement);

  const history = useHistory();

  const [modalResiliation, setModalResiliation] = useState(false);

  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState([]);
  const [subscriptionStripe, setSubscriptionStripe] = useState([]);
  useEffect(() => {
    setLoading(true);
    // Create a Checkout Session as soon as the page loads
    getPrices().then(res => {
      setPrices(res.response);
      setLoading(false);
      return res.response;
    });
    // il faut charger les infos de paiement du user si il est premiuym
    if (user && user.isPremium) {
      getSubscriptionStripe(user).then(res => {
        setSubscriptionStripe(res.response);
      });
    }

    // recupCheckoutSession(user).then(res => {
    //   console.log('JPA res recupcheckoiutsession : ', res);
    //   setUrl(res.response.url);
    // });
    // .then(data => setClientSecret(data.clientSecret));
  }, []);

  useEffect(() => {
    if (location && location.search && includes(location.search, 'session_id')) {
      // il faut reload le user
      updateUser(user, {});
    }
  }, [location]);

  // Fonction pour passer à l'étape suivante
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  // Fonction pour revenir à l'étape précédente
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // Fonction pour annuler l'abonnement
  const resiliationAbonnement = () => {
    cancelSubscription(user)
      .then(() => getSubscriptionStripe(user))
      .then(() => setModalResiliation(false));
  };
  //  on est en abonnement annuel, on veut le passer en mensuel

  // on est en abonnement mensuel, on veut le passer en annuel

  // on est en abonnement payant, on veut passer en Abonnement gratuit

  // moment.unix(value).format("MM/DD/YYYY");
  return (
    <BaseLayout headerPageTitle="Abonnements" isBack backUrl={'/mon-compte'}>
      <div className="container alignCenter">
        <div className="content">
          <Modal {...modalDefaultClass} id="modal-confirm" isOpen={modalResiliation}>
            <div className="modal-title">
              Confirmation
              <div onClick={() => setModalResiliation(false)} className="btn-close">
                <i className="far fa-times" />
              </div>
            </div>
            <div className="modal-body">
              L'abonnement se terminera à la fin de la période de facturation en cours (
              {moment.unix(subscriptionStripe.current_period_end).format('DD/MM/YYYY')})
              <div className="btn-group center">
                <button onClick={() => resiliationAbonnement()} className="btn btn-primary">
                  <span>OK</span>
                </button>
              </div>
            </div>
          </Modal>

          {!user.isPremium ? (
            <div className="col">
              <button
                // c'est juste l'affichage de l'abonnement, donc pas d'action dans onclick
                // onClick={() => {
                //   return setSelectedPack(1);
                // }}
                type="button"
                className={'box box-plan'}
              >
                <div className="box-plan-subheader">Formule actuelle</div>
                <div className="box-content">
                  <div className="box-plan-header">
                    <div className="box-plan-title">Pack découverte</div>
                    <div className="box-plan-price">Gratuit</div>
                  </div>
                  <div className="box-plan-args-list">
                    <div className="box-plan-arg">
                      <i className="fa-solid fa-check-circle" />1 commode
                    </div>
                    <div className="box-plan-arg">
                      <i className="fa-solid fa-check-circle" />1 proche désigné
                    </div>
                    <div className="box-plan-arg">
                      <i className="fa-solid fa-check-circle" />
                      Stockage 100 Mo
                    </div>
                  </div>
                </div>
              </button>
            </div>
          ) : null}
          {user.isPremium ? (
            <div className="col">
              <button
                onClick={() => {
                  return setSelectedPack(2);
                }}
                type="button"
                disabled={!isInscription}
                className={'box box-plan highlight'}
              >
                <div className="box-plan-subheader">Formule actuelle</div>
                <div className="box-content">
                  <div className="box-plan-header">
                    <div className="box-plan-title">Pack Premium</div>
                    {!loading &&
                      user.paiement === '2' &&
                      find(prices, p => {
                        return p.recurring.interval === 'year';
                      }) && (
                        <div className="box-plan-price">
                          {find(prices, p => p.recurring.interval === 'year').unit_amount / 100}{' '}
                          €/an
                        </div>
                      )}
                    {!loading &&
                      user.paiement === '1' &&
                      find(prices, p => {
                        return p.recurring.interval === 'month';
                      }) && (
                        <div className="box-plan-price">
                          {find(prices, p => p.recurring.interval === 'month').unit_amount / 100}{' '}
                          €/mois
                        </div>
                      )}
                  </div>
                  <div className="box-plan-args-list">
                    <div className="box-plan-arg">
                      <i className="fa-solid fa-check-circle" />1 commode
                    </div>
                    <div className="box-plan-arg">
                      <i className="fa-solid fa-check-circle" />6 proches désignés
                    </div>
                    <div className="box-plan-arg">
                      <i className="fa-solid fa-check-circle" />
                      Stockage 10 Go
                    </div>
                  </div>
                </div>
              </button>
            </div>
          ) : null}
          {!user.isPremium ? (
            <button onClick={() => history.push('/abonnements/update')} className="btn btn-primary">
              <span>Changer de formule</span>
            </button>
          ) : null}
          {user.isPremium ? (
            <Fragment>
              {subscriptionStripe && !subscriptionStripe.cancel_at_period_end && (
                <button onClick={() => setModalResiliation(true)} className="btn btn-primary">
                  <span>Résilier mon abonnement</span>
                </button>
              )}

              {subscriptionStripe && subscriptionStripe.cancel_at_period_end && (
                <button disabled className="btn btn-secondary">
                  <span>
                    Abonnement résilié (fin le{' '}
                    {moment.unix(subscriptionStripe.current_period_end).format('DD/MM/YYYY')} )
                  </span>
                </button>
              )}
            </Fragment>
          ) : null}
        </div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

AbonnementPage.propTypes = {
  register: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {
  register,
  getPrices,
  getSessionStripe,
  getSubscriptionStripe,
  cancelSubscription,
  updateUser
})(AbonnementPage);
