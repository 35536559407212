import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { deleteProche } from '../store/modules/proches';

import { values } from 'lodash';

import ModalConfirm from './modals/ModalConfirm';
import ModalEditDroitsAccesProche from './modals/ModalEditDroitsAccesProche';

const ProcheItem = ({ proche, user, commodes, deleteProche, blocked }) => {
  const commodeId = find(commodes, c => c.user_id === user.user_id).commode_id;
  return (
    <div className={`btn btn-proche ${blocked ? 'disabled' : ''}`}>
      <div className="proche-infos">
        <span className="proche-info-prenom">{proche.prenom}</span>
        <span className="proche-info-nom">{proche.nom}</span>
      </div>
      <div className="btn-group">
        {!blocked ? <ModalEditDroitsAccesProche proche={proche} /> : null}
        <ModalConfirm
          textButton={<i className="far fa-trash-alt" />}
          title="Supprimer un proche"
          className="btn"
          confirmMethode={() => deleteProche(user, proche, true, commodeId)}
          text="Etes-vous sûr de vouloir supprimer les accès à ce proche ? "
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  commodes: state.data.entities.commodes && values(state.data.entities.commodes)
});

ProcheItem.propTypes = {
  user: PropTypes.object.isRequired,
  proche: PropTypes.object.isRequired,
  deleteProche: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { deleteProche })(ProcheItem);
