import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';
import { CALL_API, Schemas } from '../Schemas';

const GET_TIROIRS_REQUEST = 'immowa/GET_TIROIRS_REQUEST';
const GET_TIROIRS_SUCCESS = 'immowa/GET_TIROIRS_SUCCESS';
const GET_TIROIRS_FAILURE = 'immowa/GET_TIROIRS_FAILURE';

const UPDATE_DROITS_TIROIRS_REQUEST = 'immowa/UPDATE_DROITS_TIROIRS_REQUEST';
const UPDATE_DROITS_TIROIRS_SUCCESS = 'immowa/UPDATE_DROITS_TIROIRS_SUCCESS';
const UPDATE_DROITS_TIROIRS_FAILURE = 'immowa/UPDATE_DROITS_TIROIRS_FAILURE';

export const tiroirsActionsHandlers = {
  [GET_TIROIRS_SUCCESS]: (state, action) =>
    flow(
      set('entities.tiroirs', action.response.entities.tiroirs || {}),
      set('loaded.tiroirs', true),
      set('loading.tiroirs', false)
    )(state),
  [UPDATE_DROITS_TIROIRS_SUCCESS]: (state, action) =>
    flow(
      set('entities.tiroirs', action.response.entities.tiroirs || {}),
      set('loaded.tiroirs', true),
      set('loading.tiroirs', false)
    )(state)
};

export function getTiroirs(userId, commodeId) {
  return {
    [CALL_API]: {
      types: [GET_TIROIRS_REQUEST, GET_TIROIRS_SUCCESS, GET_TIROIRS_FAILURE],
      method: 'GET',
      endpoint: `/tiroirs/${userId}/${commodeId}`,
      schema: Schemas.TIROIR_ARRAY,
      successMessage: 'Tiroirs chargés avec succès'
    }
  };
}

export function updateDroitsTiroirs(tiroirsDroits, commodeId) {
  return {
    [CALL_API]: {
      types: [
        UPDATE_DROITS_TIROIRS_REQUEST,
        UPDATE_DROITS_TIROIRS_SUCCESS,
        UPDATE_DROITS_TIROIRS_FAILURE
      ],
      method: 'PUT',
      body: tiroirsDroits,
      endpoint: `/tiroirs/${commodeId}`,
      schema: Schemas.TIROIR_ARRAY,
      successMessage: 'Tiroirs chargés avec succès'
    }
  };
}
