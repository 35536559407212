import { map, includes, toLower } from 'lodash';

export function getExtension(filename) {
  var i = filename.lastIndexOf('.');
  return i < 0 ? '' : filename.substr(i);
}

export function removeExtension(filename) {
  var lastDotPosition = filename.lastIndexOf('.');
  if (lastDotPosition === -1) return filename;
  else return toLower(filename.substr(0, lastDotPosition));
}

export function displayNamesFilesWithSize(fileName) {
  return map(
    ['640w', '1024w', '1920w'],
    taille => `${removeExtension(fileName)}-${taille}${getExtension(fileName)}`
  );
}

export function getFileType(file) {
  const fileExtension = getExtension(toLower(file));

  if (includes(['.pdf'], fileExtension)) {
    return 'pdf';
  }
  if (includes(['.png', '.jpg', '.jpeg', '.gif'], fileExtension)) {
    return 'image';
  }
  if (includes(['.mov', '.avi', '.mp4', '.wmv'], fileExtension)) {
    return 'video';
  }
  if (includes(['.m4a', '.mp3', 'wma', '.amr'], fileExtension)) {
    return 'audio';
  }
  if (includes(['.doc', '.docx'], fileExtension)) {
    return 'microsoft_word';
  }
  if (includes(['.xls', '.xlsx'], fileExtension)) {
    return 'microsoft_excel';
  }
  if (includes(['.ppt', '.pptx'], fileExtension)) {
    return 'microsoft_powerpoint';
  }
  if (includes(['.odt'], fileExtension)) {
    return 'open_office';
  }
  if (includes(['.txt'], fileExtension)) {
    return 'texte';
  }
}
