import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { createContenu, updateContenu } from '../../store/modules/contenus';
import { uploadFiles, removeFile, getProgressUpload } from '../../store/modules/uploads';
import { canAddContent, checkUseSpaceDisk } from '../../store/modules/auth';
import { getExtension, removeExtension } from '../../utils/StringUtils';

import { map, includes, filter, flatMap, union, isEmpty, sum } from 'lodash';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

import MomentUtils from '@date-io/moment';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';

import modalDefaultClass from '../../utils/ModalDefaultClass';
import FieldUploadDropZone from '../shared/FieldUploadDropZone';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#9445a3'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black'
      }
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#cbcbcb',
          borderRadius: 8
        },
        '&:hover $notchedOutline': {
          borderColor: '#9445a3'
        },
        '&$focused $notchedOutline': {
          borderColor: '#9445a3',
          borderWidth: 1
        },
        '&&& $input': {
          padding: '10px 0',
          fontSize: '15px'
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: '#9445a3'
      },
      daySelected: {
        backgroundColor: '#9445a3'
      },
      dayDisabled: {
        color: '#9445a3'
      },
      current: {
        color: '#9445a3'
      }
    },
    MuiPickersYear: {
      year: {
        color: 'red'
      },
      yearSelected: {
        color: '#9445a3'
      }
    },
    MuiButton: {
      textPrimary: {
        color: 'black'
      }
    }
  }
});
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date)
      .locale('fr')
      .format('DD MMMM YYYY');
  }
}

const ModalFormContenu = ({
  isEdit,
  contenu,
  tiroir,
  categories,
  createContenu,
  updateContenu,
  uploadFiles,
  checkUseSpaceDisk,
  removeFile,
  user,
  getProgressUpload,
  special,
  canAddContent
}) => {
  const history = useHistory();

  const [value, setValue] = useState(0);
  const [shouldCallBackend, setShouldCallBackend] = useState(false);
  useEffect(() => {
    let intervalId;

    if (shouldCallBackend) {
      intervalId = setInterval(() => {
        // Appel au backend
        getProgressUpload(user.user_id)
          .then(res => {
            setValue(res.response.value); // Met à jour la valeur avec la réponse du backend
            if (res.response && res.response.value >= 100) {
              setShouldCallBackend(false); // Arrête l'appel au backend une fois que la valeur est >= 100
            }
          })
          .catch(() => {
            setIsOpen(false);
            setShouldCallBackend(false); // Arrête l'appel au backend en cas d'erreur
          });
      }, 1000); // Appel toutes les 1000ms (1 seconde)
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    return () => {
      clearInterval(intervalId); // Nettoyage de l'intervalle lors du démontage du composant
    };
  }, [shouldCallBackend]); // Exécute lorsque shouldCallBackend change

  useEffect(() => {
    // Init timer 0
    setValue(0);
  }, []);

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalBlocage, setModalBlocage] = useState(false);
  const [modalFreemium, setModalFreemium] = useState(false);
  const [modalPremium, setModalPremium] = useState(false);

  const [error, setError] = useState(null);

  const [filesIsWaitDelete, setfilesIsWaitDelete] = useState([]);

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredCategories = value => (value && value.length > 0 ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    const data = values;

    data.user_id = special && contenu ? contenu?.user_id : user?.user_id;
    data.nom = special ? user?.nom : null;
    data.prenom = special ? user?.prenom : null;
    data.tiroir_id = tiroir?.tiroir_id;

    if (isEdit) {
      /*
      -----------------------------------------------
      EDITION D'UN CONTENU
      -----------------------------------------------
      */

      setLoading(true);

      const newFichiers = filter(data.fichiers, f => f.path);

      let tempDatafiles = (contenu.fichiers && contenu.fichiers) || [];

      return Promise.resolve(() => 'good')
        .then(() => {
          // DES FICHIERS A DELETE
          if (!isEmpty(filesIsWaitDelete)) {
            return removeFile(filesIsWaitDelete, contenu.user_id);
          } else {
            return 'not_delete';
          }
        })
        .then(res => {
          if (res !== 'not_delete') {
            tempDatafiles = filter(tempDatafiles, f => !includes(filesIsWaitDelete, f.fileName));
          }
          return;
        })
        .then(() => {
          console.log('temp');
          // TODO ->
          // faire le calcul de l'image qu'on doit supprimer pour voir si on peut ajouter l'autre qui la remplace
          // JPA filesIsWaitDelete :  (4) ['20230717-174357-agmm1561.jpg', '20230717-174357-agmm1561-640w.jpg', '20230717-174357-agmm1561-1024w.jpg', '20230717-174357-agmm1561-1920w.jpg']
          // if (isEmpty(newFichiers)) {
          //   setTimeout(() => {
          //     setShouldCallBackend(true);
          //   }, 1500);

          //   return checkUseSpaceDisk(user.user_id)
          //     .then(res => {
          //       let filesToAdd = sum(map(data.fichiers, file => file.size / 1000000));
          //       let freeSpace = 0;
          //       if (!user.Premium) {
          //         // il n'a que 100 Mo d'espace pour les freemium
          //         freeSpace = 100 - filesToAdd + res.response.useDiskAvailable;
          //       }
          //       if (user.Premium) {
          //         // on n'a 10 Giga si on est en premium
          //         freeSpace = 10000 - filesToAdd + res.response.useDiskAvailable;
          //       }
          //       if (freeSpace < 0) {
          //         console.log('on peut ajouter ! : ', freeSpace);
          //         // on peut ajouter
          //         // L'utilisateur peut ajouter des fichiers, continuez avec l'uploadFiles
          //         // return uploadFiles({ files: data.fichiers }, user.user_id)
          //         //   .then(res => {
          //         //     setShouldCallBackend(false);
          //         //     setIsOpen(false);
          //         //     setLoading(false);
          //         //     return res;
          //         //   })
          //         //   .catch(err => {
          //         //     setShouldCallBackend(false);
          //         //     setError(err);
          //         //     setLoading(false);
          //         //   });
          //       } else {
          //         // on reject une erreur
          //         throw String('Espace disque insuffisant');
          //       }
          //     })
          //     .catch(err => {
          //       setError(err);
          //       if (user && !user.Premium) {
          //         setModalFreemium(true);
          //       }
          //       if (user && user.Premium) {
          //         setModalPremium(true);
          //       }
          //       setLoading(false);
          //     });
          //   // // si on doit ajouter un fichier, on doit regarder si l'utilisateur a la capacité de pouvoir en ajouter :
          //   // return checkUseSpaceDisk(user.user_id).then(canAddFiles => {
          //   //   if (canAddFiles) {
          //   //     // L'utilisateur peut ajouter des fichiers, continuez avec l'uploadFiles
          //   //     return uploadFiles({ files: data.fichiers }, user.user_id)
          //   //       .then(res => {
          //   //         setShouldCallBackend(false);
          //   //         setIsOpen(false);
          //   //         setLoading(false);
          //   //         return res;
          //   //       })
          //   //       .catch(err => {
          //   //         setShouldCallBackend(false);
          //   //         setError(err);
          //   //         setLoading(false);
          //   //       });
          //   //   } else {
          //   //     // L'utilisateur n'est pas autorisé à ajouter des fichiers, gestion appropriée ici
          //   //     setLoading(false);
          //   //     console.log("L'utilisateur n'est pas autorisé à ajouter des fichiers.");
          //   //   }
          //   // });
          // } else {

          //   return 'not_add_file';
          // }
        })
        .then(res => {
          if (res !== 'not_add_file') {
            tempDatafiles = [...tempDatafiles, ...map(res.response, r => r)];
          }
          return;
        })
        .then(res => {
          data.fichiers = tempDatafiles;

          return updateContenu(contenu, data)
            .then(() => {
              setfilesIsWaitDelete([]);
              setIsOpen(false);
              setLoading(false);
            })
            .catch(err => {
              setfilesIsWaitDelete([]);
              setError(err);
              setLoading(false);
            });
        })
        .catch(err => {
          setError(err);
        });
    } else {
      /*
      -----------------------------------------------
      CREATION D'UN CONTENU
      -----------------------------------------------
      */
      data.commentaires = [];
      // setLoading(true);

      if (data.fichiers && !isEmpty(data.fichiers)) {
        // setTimeout(() => {
        //   setShouldCallBackend(true);
        // }, 1500);

        return checkUseSpaceDisk(user.user_id)
          .then(res => {
            let filesToAdd = sum(map(data.fichiers, file => file.size / 1000000));
            let freeSpace = 0;
            if (!user.isPremium) {
              // il n'a que 100 Mo d'espace pour les freemium
              freeSpace = 100 - filesToAdd - res.response.useDiskAvailable;
            }
            if (user.isPremium) {
              // on n'a 10 Giga si on est en premium
              freeSpace = 10000 - filesToAdd - res.response.useDiskAvailable;
            }
            if (freeSpace > 0) {
              // on peut ajouter
              return uploadFiles({ files: data.fichiers }, user.user_id)
                .then(res => {
                  data.fichiers = map(res?.response, r => r);
                  return createContenu(data)
                    .then(res => {
                      setShouldCallBackend(false);
                      setIsOpen(false);
                      setLoading(false);
                    })
                    .catch(err => {
                      setShouldCallBackend(false);
                      setError(err);
                      setLoading(false);
                    });
                })
                .catch(err => {
                  setShouldCallBackend(false);
                  setError(err);
                  setLoading(false);
                });
            } else {
              // on reject une erreur
              throw String('Espace disque insuffisant');
            }
          })
          .catch(err => {
            setError(err);
            if (user && !user.Premium) {
              setModalFreemium(true);
            }
            if (user && user.Premium) {
              setModalPremium(true);
            }
            setLoading(false);
          });
        // a rajouter
        // // si on doit ajouter un fichier, on doit regarder si l'utilisateur a la capacité de pouvoir en ajouter :
        // return checkUseSpaceDisk(user.user_id, { files: data.fichiers }).then(canAddFiles => {
        //   if (canAddFiles) {
        //     // L'utilisateur peut ajouter des fichiers, continuez avec l'uploadFiles
        //     return uploadFiles({ files: data.fichiers }, user.user_id)
        //       .then(res => {
        //         data.fichiers = map(res?.response, r => r);
        //         return createContenu(data)
        //           .then(res => {
        //             setShouldCallBackend(false);
        //             setIsOpen(false);
        //             setLoading(false);
        //           })
        //           .catch(err => {
        //             setShouldCallBackend(false);
        //             setError(err);
        //             setLoading(false);
        //           });
        //       })
        //       .catch(err => {
        //         setShouldCallBackend(false);
        //         setError(err);
        //         setLoading(false);
        //       });
        //   } else {
        //     // L'utilisateur n'est pas autorisé à ajouter des fichiers, gestion appropriée ici
        //     setLoading(false);
        //     console.log("L'utilisateur n'est pas autorisé à ajouter des fichiers.");
        //   }
        // });
      } else {
        data.fichiers = [];
        return createContenu(data)
          .then(res => {
            setIsOpen(false);
            setLoading(false);
          })
          .catch(err => {
            setError(err);
            setLoading(false);
          });
      }
    }
  };

  const prepareDeleteFileContenu = file => {
    // stock tous les fichiers présent dans le contenu déja enregister qui doivent etre supprimmer

    const getFileWhereFileType = file => {
      switch (file.fileType) {
        case 'image':
          return union(
            [file.fileName],
            map(
              ['640w', '1024w', '1920w'],
              taille => `${removeExtension(file.fileName)}-${taille}${getExtension(file.fileName)}`
            )
          );
        case 'video':
          return union([file.fileName], [`min_${file.fileName}`]);
        case 'audio':
          return file.fileName;
        case 'pdf':
        case 'microsoft_word':
        case 'microsoft_excel':
        case 'microsoft_powerpoint':
        case 'open_office':
        case 'texte':
          return file.fileName;
      }
    };

    const files = [...filesIsWaitDelete, getFileWhereFileType(file)];
    setfilesIsWaitDelete(flatMap(files));
  };

  console.log('JPA filesIsWaitDelete : ', filesIsWaitDelete);

  return (
    <Fragment>
      {isEdit ? (
        <button
          className="btn"
          onClick={() => {
            setError(null);
            setIsOpen(!isOpen);
          }}
        >
          <i className="fas fa-pen" />
        </button>
      ) : (
        <button
          className="btn-add"
          onClick={() => {
            setError(null);
            setValue(0);
            // setModalFreemium(true);
            // TODO a remettre
            // commenté pour tester le blocage en version freemium
            if (user.bloquageContenu) {
              setModalBlocage(true);
            } else {
              setIsOpen(!isOpen);
            }
            if (user && user.obendy) {
              // on check son quota
              // canAddContent(user.user_id).then(res => console.log('JPA res : ', res));
            }
          }}
        >
          <i className="fas fa-plus" />
          <span>Ajouter un contenu</span>
        </button>
      )}
      {modalBlocage && (
        <Modal
          className="modal-content"
          overlayClassName="modal-overlay modal-custom"
          id="modal-bloquage-contenu"
          isOpen={modalBlocage}
        >
          <div className="modal-title">
            Blocage contenu
            <div onClick={() => setModalBlocage(false)} className="btn-close">
              <i className="far fa-times" />
            </div>
          </div>
          <div className="modal-body">
            BLOCAGE CONTENU
            <div className="btn-group center">
              <button onClick={() => setModalBlocage()} className="btn btn-primary">
                <span>OK</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
      {(modalFreemium || modalPremium) && (
        <Modal
          className="modal-content"
          overlayClassName="modal-overlay modal-custom"
          id="modal-bloquage-contenu"
          isOpen={modalFreemium || modalPremium}
        >
          <div className="modal-title">
            <div
              onClick={() => {
                setModalFreemium(false);
                // avant, on redirigeait sur l'ajout d'un proche, maintenant on redirige sur la modificatino de l'abonnemnet
                // du coup, on n'a plus a ouvrir la modal si on ferme la modal avec les infos pour aller sur mode payant
                // setIsOpen(true);
              }}
              className="btn-close"
            >
              <i className="far fa-times" />
            </div>
          </div>
          <div className="modal-body">
            <i className="fal fa-triangle-exclamation modal-custom-icon" />
            <p>
              Vous ne pouvez pas ajouter un nouveau fichier, vous avez atteint votre limite de
              stockage
            </p>
            {modalFreemium ? (
              <p>Passez à la formule Premium et bénéficiez de 10&nbsp;Go de stockage !</p>
            ) : null}
            {modalPremium ? (
              <a href="https://www.immortaliz.fr/contact" target="_blank" rel="noopener">
                Contactez-nous
              </a>
            ) : null}
            {modalFreemium ? (
              <div className="btn-group center">
                <div
                  className="btn btn-secondary"
                  onClick={() => {
                    history.push('/abonnements/update');
                  }}
                >
                  Je découvre la formule Premium !
                </div>
              </div>
            ) : null}
          </div>
        </Modal>
      )}

      {isOpen && (
        <Modal
          className="modal-content full-modal"
          overlayClassName="modal-overlay modal-add-content"
          id="modal-contenu"
          isOpen={isOpen}
          tiroir={tiroir}
        >
          <div className="modal-title">
            {(isEdit && 'Modifier le contenu') || "J'ajoute un contenu"}
            <div
              onClick={() => {
                setIsOpen(false);
              }}
              className="btn-close"
            >
              <i className="far fa-times" />
            </div>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={handleSubmit}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="titre"
                    initialValue={(isEdit && contenu && contenu.titre) || ''}
                    validate={required}
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" />
                        <label>Titre *</label>
                      </div>
                    )}
                  </Field>
                  {tiroir.value === 'ma-vie' && (
                    <Field
                      name="date_contenu"
                      initialValue={(isEdit && contenu.date_contenu) || ''}
                      validate={required}
                      component="input"
                      className="field"
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={LocalizedUtils}
                            locale={'fr'}
                          >
                            <ThemeProvider theme={materialTheme}>
                              <KeyboardDatePicker
                                className="datepicker-mui"
                                invalidDateMessage={null}
                                autoOk
                                value={(input && input.value) || null}
                                placeholder="jj/mm/aaaa"
                                okLabel={null}
                                clearLabel={null}
                                clearable={false}
                                cancelLabel={null}
                                onChange={value => {
                                  input.onChange(value);
                                }}
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{ position: 'start' }}
                                inputVariant="outlined"
                                maxDate={new Date()}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                          <label>Date *</label>
                        </div>
                      )}
                    </Field>
                  )}
                  <Field
                    name="description"
                    initialValue={(isEdit && contenu && contenu.description) || ''}
                    component="input"
                    className="field"
                    parse={v => v}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <textarea {...input} type="text" />
                        <label>Description</label>
                      </div>
                    )}
                  </Field>
                  <Field
                    name="categories"
                    initialValue={isEdit && contenu && contenu.categories}
                    validate={requiredCategories}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={valeurs => {
                              input.onChange(map(valeurs, v => v.value));
                            }}
                            defaultValue={
                              (isEdit &&
                                map(
                                  filter(categories, c =>
                                    includes(contenu.categories, c.categorie_id)
                                  ),
                                  categorie => {
                                    return {
                                      value: categorie.categorie_id,
                                      label: categorie.label
                                    };
                                  }
                                )) ||
                              []
                            }
                            placeholder="Sélectionner la (les) catégorie(s)"
                            isMulti
                            name="utilisateurs"
                            options={map(categories, c => ({
                              value: c.categorie_id,
                              label: c.label
                            }))}
                          />
                          <label>Catégories *</label>
                        </div>
                      );
                    }}
                  </Field>

                  <label>Fichier(s)</label>

                  <Field
                    name="fichiers"
                    initFile={contenu && isEdit && contenu.fichiers}
                    initialValue={contenu && isEdit && contenu.fichiers}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="field field-dropzone">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          <FieldUploadDropZone
                            editMode={isEdit}
                            initFiles={contenu && isEdit && contenu.fichiers}
                            userId={special ? contenu?.user_id : user?.user_id}
                            prepareDeleteFileContenu={file => prepareDeleteFileContenu(file)}
                            rmFile={file =>
                              Promise.resolve().then(() => {
                                return removeFile(file, special ? contenu.user_id : user.user_id);
                              })
                            }
                            acceptedFiles={[
                              '.pdf',
                              '.doc',
                              '.docx',
                              '.xls',
                              '.xlsx',
                              '.ppt',
                              '.pptx',
                              '.odt',
                              '.txt',
                              '.png',
                              '.jpg',
                              '.jpeg',
                              '.gif',
                              '.mov',
                              '.wmv',
                              '.avi',
                              '.mp4',
                              '.mp3',
                              '.wma',
                              '.m4a',
                              '.amr'
                            ]}
                            {...input}
                          />
                          <div className="filetypes">
                            Type de fichiers autorisés : pdf, doc, docx, xls, xlsx, ppt, pptx, odt,
                            txt, png, jpg, jpeg, gif, mov, wmv, avi, mp4, mp3, wma, m4a, am
                          </div>
                        </div>
                      );
                    }}
                  </Field>

                  {error && <div className="error-block">{error}</div>}
                  <div className="btn-group center">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                      <span>
                        {loading ? (
                          <div>
                            <ClipLoader size={15} color={'white'} loading={loading} />
                            <div>{(value && Math.trunc(value)) || 0}%</div>
                          </div>
                        ) : (
                          (isEdit && 'Enregistrer') || 'Ajouter'
                        )}
                      </span>
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

ModalFormContenu.propTypes = {
  createContenu: PropTypes.func.isRequired,
  updateContenu: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  tiroir: PropTypes.object.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});
export default connect(mapStateToProps, {
  createContenu,
  updateContenu,
  uploadFiles,
  checkUseSpaceDisk,
  removeFile,
  getProgressUpload,
  canAddContent
})(ModalFormContenu);
