import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { map, find, union, filter, includes, isUndefined, orderBy, cloneDeep, pull } from 'lodash';
import BaseLayout from './shared/BaseLayout';
import ButtonsSelector from '../components/shared/ButtonsSelector';
import ModalFormContenu from '../components/modals/ModalFormContenu';
import ContenuCard from '../components/ContenuCard';
import ContenuCardTimeline from '../components/ContenuCardTimeline';
import ModalAddCategorie from '../components/modals/ModalAddCategorie';
import ModalRemoveCategorie from '../components/modals/ModalRemoveCategorie';
import { getContenus } from '../store/modules/contenus';
import { getProches } from '../store/modules/proches';

const TiroirPage = ({
  tiroir,
  tiroirs,
  categories,
  commodes,
  proches,
  user,
  getContenus,
  getProches,
  contenusProps
}) => {
  // #5389 on initialise la valeur par defaut à 1 qui
  // représente la valeur "TOUS"
  const [selectedFilter, setSelectedFilter] = useState(1);
  const [contenus, setContenus] = useState(contenusProps);

  const [isOpen, setIsOpen] = useState(false);
  const [removeCategorie, setRemoveCategorie] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState(null);

  const { tiroirId, tiroirName } = useParams();
  const tiroirAuthor = commodes[tiroirs[tiroir.tiroir_id].commode_id].user_id;
  const proche = proches[commodes[tiroirs[tiroir.tiroir_id].commode_id].user_id];
  const commode = find(commodes, c => tiroir.user_id === c.user_id);

  const history = useHistory();
  const location = useLocation();
  const dataState = location.state;

  useEffect(() => {
    getProches(user.user_id);
  }, []);

  useEffect(() => {
    if (isUndefined(tiroirs[tiroirId])) {
      return history.push('/');
    } else {
      getContenus(tiroir.tiroir_id || tiroirId);
    }
  }, []);

  useEffect(() => {
    const contenusData = cloneDeep(contenusProps);

    if (selectedFilter === 1) {
      setContenus(orderBy(contenusData, 'updated_at', 'desc'));
    } else {
      setContenus(
        orderBy(
          filter(contenusData, c => includes(c.categories, selectedFilter)),
          'updated_at',
          'desc'
        )
      );
    }
  }, [contenusProps]);

  const onChangeFilter = value => {
    window.scrollTo(0, 0);
    setSelectedFilter(value);
    if (value === 1) {
      setContenus(contenusProps);
    } else {
      setContenus(filter(contenusProps, c => includes(c.categories, value)));
    }
  };

  // ON REORGANISE LES CATEGORIES AFFICHEES POUR REPLACER "AUTRES DOCUMENTS" A LA FIN DE LA LISTE
  let categoriesReordered = cloneDeep(categories);
  pull(categoriesReordered, categoriesReordered[7]);
  categoriesReordered.push(categories[7]);

  return (
    <BaseLayout
      isBack={true}
      headerPageTitle={tiroir && tiroir.nom}
      proche={proche}
      classScreen="page-tiroir"
    >
      <div className="container">
        <div className="tabs tabs-noWrap">
          <ButtonsSelector
            selected={selectedFilter}
            onClickButton={value => onChangeFilter(value)}
            buttons={map(
              union([{ label: 'Tous', categorie_id: 1 }], categoriesReordered),
              categorie => {
                return (
                  categorie &&
                  categorie.label &&
                  categorie.categorie_id && {
                    label: categorie.label,
                    value: categorie.categorie_id
                  }
                );
              }
            )}
          />
          {isUndefined(proche) && (
            <button
              className="tab"
              onClick={() => {
                setError(null);
                setIsEdit(false);
                // qu'est ce que le blocage contenu ????
                if (user.bloquageContenu) {
                  setModalBlocage(true);
                } else {
                  setIsOpen(!isOpen);
                }
              }}
            >
              <i className="fas fa-plus" />
            </button>
          )}
        </div>

        <div className="block-titre-categorie">
          <div className="titre-categorie">
            {(find(categories, cat => cat.categorie_id === selectedFilter) &&
              find(categories, cat => cat.categorie_id === selectedFilter).label) ||
              'Tous'}
          </div>
          {selectedFilter !== 1 && isUndefined(proche) && (
            <Fragment>
              <button
                className="btn"
                onClick={() => {
                  setError(null);
                  setIsEdit(true);
                  setIsOpen(!isOpen);
                }}
              >
                <i className="far fa-pen" />
              </button>
              <button
                className="btn"
                onClick={() => {
                  setError(null);
                  setRemoveCategorie(!removeCategorie);
                }}
              >
                <i className="far fa-trash-alt" />
              </button>
            </Fragment>
          )}
        </div>
        <div className="souvenirs-block">
          {tiroirName === 'ma-vie'
            ? map(orderBy(contenus, 'updated_at', 'desc'), contenu => {
                return (
                  <ContenuCardTimeline
                    key={contenu.contenu_id}
                    contenu={contenu}
                    tiroir={tiroir}
                    categories={categories}
                    isAuthor={contenu.user_id === user.user_id}
                  />
                );
              })
            : map(orderBy(contenus, 'updated_at', 'desc'), contenu => {
                return (
                  <ContenuCard
                    key={contenu.contenu_id}
                    contenu={contenu}
                    tiroir={tiroir}
                    commode={commode}
                    categories={categories}
                    isAuthor={contenu.user_id === user.user_id}
                    special={dataState.special}
                  />
                );
              })}
          {(tiroirAuthor === user.user_id || dataState.special) && (
            <ModalFormContenu tiroir={tiroir} categories={categories} special={dataState.special} />
          )}
        </div>
        {isOpen && (
          <ModalAddCategorie
            categorie={find(categories, cat => cat.categorie_id === selectedFilter)}
            isOpen={isOpen}
            isEdit={isEdit}
            setIsOpen={() => setIsOpen(!isOpen)}
            setSelectedFilter={v => setSelectedFilter(v)}
          />
        )}
        {removeCategorie && (
          <ModalRemoveCategorie
            categorie={find(categories, cat => cat.categorie_id === selectedFilter)}
            removeCategorie={removeCategorie}
            contenus={contenus}
            setSelectedFilter={v => setSelectedFilter(v)}
            setRemoveCategorie={() => setRemoveCategorie(!removeCategorie)}
          />
        )}
      </div>
    </BaseLayout>
  );
};

TiroirPage.propTypes = {
  user: PropTypes.object.isRequired,
  tiroir: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  getContenus: PropTypes.func.isRequired,
  getProches: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { tiroirId, tiroirName }
    }
  } = props;
  return {
    user: state.auth.user,
    commodes: state.data.entities.commodes,
    proches: state.data.entities.proches,
    tiroirs: state.data.entities.tiroirs,
    tiroir: state.data.entities.tiroirs && state.data.entities.tiroirs[tiroirId],
    categories: state.data.entities.categories[tiroirName].categories,
    contenusProps:
      state.data.entities.contenus &&
      filter(state.data.entities.contenus, c => c.tiroir_id === Number(tiroirId))
  };
};

export default connect(mapStateToProps, { getContenus, getProches })(TiroirPage);
