import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { register } from '../../store/modules/auth';

import BaseLayout from '../shared/BaseLayout';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

import MomentUtils from '@date-io/moment';

import InformationCreationClient from './InformationCreationClient';
import SelectPack from './SelectPack';
import CheckoutForm from '../stripe/CheckoutForm';

const AbonnementUpdatePage = ({ register, userStore }) => {
  const [currentStep, setCurrentStep] = useState(1);
  // on l'init à 2 pour avoir le pack abonnement payant préselectionné.
  const [selectedPack, setSelectedPack] = useState(2);

  const history = useHistory();
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);

  // Fonction pour passer à l'étape suivante
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  // Fonction pour revenir à l'étape précédente
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  return (
    <BaseLayout headerPageTitle="Abonnements" isBack>
      <div className="container alignCenter">
        <div className="logo-block">
          <div className="title">
            Etape {currentStep}&nbsp;:&nbsp;{currentStep === 1 && 'Choix de la formule'}
            {currentStep === 2 && 'Paiement'}
          </div>
        </div>

        <Fragment>
          {currentStep === 1 && (
            <SelectPack
              nextStep={
                // vu qu'on a qu'une seule version premium, on met à jour le user pour le ispremium + les infos de base du user
                () => {
                  setUser({
                    ...userStore,
                    isPremium: true
                  });
                  nextStep();
                }
              }
              prevStep={prevStep}
              selectedPack={selectedPack}
              setSelectedPack={p => setSelectedPack(p)}
            />
          )}
          {currentStep === 2 && (
            <CheckoutForm
              nextStep={nextStep}
              prevStep={prevStep}
              user={user}
              selectedPack={selectedPack}
            />
          )}
        </Fragment>
      </div>
    </BaseLayout>
  );
};

AbonnementUpdatePage.propTypes = {
  register: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  userStore: state.auth.user
});

export default connect(mapStateToProps, { register })(AbonnementUpdatePage);
