import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

import initialState from '../initialState';
const GET_ALL_SETTINGS = 'immowa/GET_ALL_SETTINGS_REQUEST';
const PURGE = 'immowa/PURGE';
const GET_ALL_SETTINGS_SUCCESS = 'immowa/GET_ALL_SETTINGS_SUCCESS';
const GET_ALL_SETTINGS_FAILURE = 'immowa/GET_ALL_SETTINGS_FAILURE';

export const settingsActionsHandlers = {
  [PURGE]: () => initialState,
  [GET_ALL_SETTINGS]: state =>
    flow(set('loaded.settings', false), set('loading.settings', true))(state),
  [GET_ALL_SETTINGS_SUCCESS]: (state, action) =>
    flow(
      set('entities.settings', action.response.entities.settings || {}),
      set('loaded.settings', true),
      set('loading.settings', false)
    )(state),
  [GET_ALL_SETTINGS_FAILURE]: state =>
    flow(set('loaded.settings', false), set('loading.settings', false))(state)
};

export function purge() {
  return {
    type: PURGE
  };
}

export function loadSettings() {
  return {
    [CALL_API]: {
      types: [GET_ALL_SETTINGS, GET_ALL_SETTINGS_SUCCESS, GET_ALL_SETTINGS_FAILURE],
      method: 'GET',
      endpoint: `/settings`,
      schema: Schemas.SETTING_ARRAY,
      successMessage: 'Documents chargés avec succès'
    }
  };
}
