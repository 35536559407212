import { CALL_API } from '../Schemas';

const UPLOADS_REQUEST = 'immowa/uploads/UPLOADS_REQUEST';
const UPLOADS_SUCCESS = 'immowa/uploads/UPLOADS_SUCCESS';
const UPLOADS_FAILURE = 'immowa/uploads/UPLOADS_FAILURE';
const GET_PROGRESS_UPLOADS_REQUEST = 'immowa/uploads/GET_PROGRESS_UPLOADS_REQUEST';
const GET_PROGRESS_UPLOADS_SUCCESS = 'immowa/uploads/GET_PROGRESS_UPLOADS_SUCCESS';
const GET_PROGRESS_UPLOADS_FAILURE = 'immowa/uploads/GET_PROGRESS_UPLOADS_FAILURE';
const DELETE_REQUEST = 'immowa/uploads/DELETE_REQUEST';
const DELETE_SUCCESS = 'immowa/uploads/DELETE_SUCCESS';
const DELETE_FAILURE = 'immowa/uploads/DELETE_FAILURE';

export const uploadsActionsHandlers = {};

export function uploadFiles(files, userId) {
  return {
    userId,
    [CALL_API]: {
      types: [UPLOADS_REQUEST, UPLOADS_SUCCESS, UPLOADS_FAILURE],
      method: 'POST',
      endpoint: `/uploads/${userId}`,
      body: files,
      upload: true
    }
  };
}
export function getProgressUpload(userId) {
  return {
    userId,
    [CALL_API]: {
      types: [
        GET_PROGRESS_UPLOADS_REQUEST,
        GET_PROGRESS_UPLOADS_SUCCESS,
        GET_PROGRESS_UPLOADS_FAILURE
      ],
      method: 'GET',
      endpoint: `/uploads/progress/${userId}`
      // body: files,
      // upload: true
    }
  };
}

export function removeFile(filesToRemove, userId) {
  return {
    userId,
    [CALL_API]: {
      types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
      method: 'DELETE',
      endpoint: `/uploads/${userId}`,
      body: {
        filesToRemove
      },
      upload: true
    }
  };
}
