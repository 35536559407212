import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { validateEmail } from '../utils/StringValidator';

import { connect } from 'react-redux';
import { updateUser } from '../store/modules/auth';
import { uploadFiles, removeFile } from '../store/modules/uploads';

import { Form, Field } from 'react-final-form';

import BaseLayout from './shared/BaseLayout';
import FieldUploadDropZone from '../components/shared/FieldUploadDropZone';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');
import MomentUtils from '@date-io/moment';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#9445a3'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black'
      }
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#cbcbcb',
          borderRadius: 8
        },
        '&:hover $notchedOutline': {
          borderColor: '#9445a3'
        },
        '&$focused $notchedOutline': {
          borderColor: '#9445a3',
          borderWidth: 1
        },
        '&&& $input': {
          padding: '10px 0',
          fontSize: '15px'
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: '#9445a3'
      },
      daySelected: {
        backgroundColor: '#9445a3'
      },
      dayDisabled: {
        color: '#9445a3'
      },
      current: {
        color: '#9445a3'
      }
    },
    MuiPickersYear: {
      year: {
        color: 'red'
      },
      yearSelected: {
        color: '#9445a3'
      }
    },
    MuiButton: {
      textPrimary: {
        color: 'black'
      }
    }
  }
});

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date)
      .locale('fr')
      .format('DD MMMM YYYY');
  }
}

const MesInfosPersonnelsPage = ({ user, updateUser, uploadFiles, removeFile }) => {
  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';
  const [selectedDate, setSelectedDate] = useState(user.date_naissance || null);

  const handleSubmit = data => {
    if (data.profil && data.profil !== user.profil) {
      return uploadFiles({ files: data.profil }, user.user_id)
        .then(res => {
          if (res && res.response && res.response[0] && res.response[0].fileName) {
            data.profil = res.response[0].fileName;
          }

          return updateUser(user, data)
            .then(res => {
              return history.push('/mon-compte');
            })
            .catch(err => setError(err));
        })
        .catch(err => setError(err));
    } else {
      return updateUser(user, data)
        .then(res => {
          return history.push('/mon-compte');
        })
        .catch(err => setError(err));
    }
  };

  const history = useHistory();

  return (
    <BaseLayout headerPageTitle="Mes infos personnelles" isBack>
      <div className="container alignCenter">
        <div className="content">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="prenom"
                  validate={required}
                  initialValue={user && user.prenom}
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" disabled={user && user.obendy} />
                      <label>Prénom *</label>
                    </div>
                  )}
                </Field>

                <Field
                  validate={required}
                  initialValue={user && user.nom}
                  name="nom"
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" disabled={user && user.obendy} />
                      <label>Nom *</label>
                    </div>
                  )}
                </Field>

                <Field
                  validate={requiredEmail}
                  initialValue={user && user.email}
                  name="email"
                  component="input"
                  className="field"
                >
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input {...input} type="text" disabled={user && user.obendy} />
                      <label>Adresse e-mail *</label>
                    </div>
                  )}
                </Field>

                {user && !user.obendy && (
                  <Field
                    name="date_naissance"
                    component="input"
                    className="field"
                    initialValue={user && user.date_naissance}
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={LocalizedUtils}
                          locale={'fr'}
                        >
                          <ThemeProvider theme={materialTheme}>
                            <KeyboardDatePicker
                              className="datepicker-mui"
                              invalidDateMessage={null}
                              autoOk
                              value={selectedDate}
                              placeholder="jj/mm/aaaa"
                              okLabel={null}
                              clearLabel={null}
                              clearable={false}
                              cancelLabel={null}
                              onChange={date => {
                                setSelectedDate(date);
                                input.onChange(date);
                              }}
                              format="DD/MM/YYYY"
                              InputAdornmentProps={{ position: 'start' }}
                              inputVariant="outlined"
                              maxDate={new Date()}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        <label>Date de naissance</label>
                      </div>
                    )}
                  </Field>
                )}
                {user && !user.obendy && (
                  <Field
                    name="profil"
                    initFile={(user && user.profil && [user.profil]) || null}
                    initialValue={user && user.profil}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          <FieldUploadDropZone
                            isEdit={true}
                            userId={user.user_id}
                            isSingleFile
                            initFiles={
                              (user.profil && [{ fileName: user.profil, fileType: 'image' }]) ||
                              null
                            }
                            type="image"
                            prepareDeleteFileContenu={file => {
                              const data = user;
                              data.profil = null;
                              removeFile(input.value, user.user_id).then(() => {
                                updateUser(user, data);
                              });
                            }}
                            rmFile={file => {
                              removeFile(file, user.user_id);
                            }}
                            acceptedFiles={['.png', '.jpg', '.jpeg', '.gif']}
                            {...input}
                          />
                          <label>Photo</label>
                        </div>
                      );
                    }}
                  </Field>
                )}

                {error && <div className="error-block">{error}</div>}
                {user && !user.obendy && (
                  <div className="btn-group sticky center">
                    <button type="submit" className="btn btn-primary">
                      <span>Enregistrer</span>
                    </button>
                  </div>
                )}
              </form>
            )}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

MesInfosPersonnelsPage.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, { updateUser, uploadFiles, removeFile })(
  MesInfosPersonnelsPage
);
