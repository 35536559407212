import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, includes, filter, omit, isEmpty, values, get, orderBy } from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
import MomentUtils from '@date-io/moment';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import { moveContenu } from '../../store/modules/contenus';
import ContenuFilesRender from '../shared/ContenuFilesRender';
import { NOMS_TIROIRS } from '../../constants/Properties';

moment.locale('fr');

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#9445a3'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black'
      }
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#cbcbcb',
          borderRadius: 8
        },
        '&:hover $notchedOutline': {
          borderColor: '#9445a3'
        },
        '&$focused $notchedOutline': {
          borderColor: '#9445a3',
          borderWidth: 1
        },
        '&&& $input': {
          padding: '10px 0',
          fontSize: '15px'
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: '#9445a3'
      },
      daySelected: {
        backgroundColor: '#9445a3'
      },
      dayDisabled: {
        color: '#9445a3'
      },
      current: {
        color: '#9445a3'
      }
    },
    MuiPickersYear: {
      year: {
        color: 'red'
      },
      yearSelected: {
        color: '#9445a3'
      }
    },
    MuiButton: {
      textPrimary: {
        color: 'black'
      }
    }
  }
});
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date)
      .locale('fr')
      .format('DD MMMM YYYY');
  }
}

const ModalMoveContenu = ({
  contenu,
  tiroir,
  tiroirs,
  commode,
  allCategories,
  proches,
  moveContenu,
  user,
  isOpen,
  setIsOpen
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [tiroirChosen, setTiroirChosen] = useState(null);
  const tiroirOptions = orderBy(
    map(
      filter(values(allCategories), c => c._id !== 'postez-moi'),
      item => {
        return {
          value: item._id,
          label: NOMS_TIROIRS[item._id].value,
          id: NOMS_TIROIRS[item._id].id
        };
      }
    ),
    ['id'],
    ['asc']
  );
  const categoriesFilter = filter(values(allCategories), c => c._id === tiroirChosen);
  const categoriesOptions =
    categoriesFilter &&
    categoriesFilter[0] &&
    map(categoriesFilter[0].categories, item => {
      return { value: item.categorie_id, label: item.label };
    });

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredCategories = value => (value && value.length > 0 ? undefined : 'Champ requis !');

  const tiroirsChoix = map(tiroirs, item => {
    return { value: item.tiroir_id, label: item.value };
  });

  const handleSubmit = values => {
    let data = omit(values, 'tiroir');
    data.fichiers = contenu?.fichiers && contenu.fichiers;
    data.creator_id = contenu.user_id;
    data.user_id = user.user_id;
    const tiroirDestination = filter(tiroirsChoix, t => t.label === values.tiroir);
    data.tiroir_id = tiroirDestination && tiroirDestination[0].value;

    setLoading(true);

    return Promise.resolve()
      .then(() => {
        return moveContenu(contenu, data, data.user_id)
          .then(() => {
            setLoading(false);
            setIsOpen(false);
          })
          .catch(err => {
            setError(err);
            setLoading(false);
          });
      })
      .catch(err => {
        console.log('err', err);
        setError(err);
      });
  };

  return (
    <Fragment>
      {isOpen && (
        <Modal
          className="modal-content full-modal"
          overlayClassName="modal-overlay modal-add-content"
          id="modal-contenu"
          isOpen={isOpen}
          tiroir={tiroir}
        >
          <div className="modal-title">
            "Je déplace un contenu"
            <div
              onClick={() => {
                setIsOpen(false);
              }}
              className="btn-close"
            >
              <i className="far fa-times" />
            </div>
          </div>
          <div className="modal-body">
            <div className="souvenir-header">
              <div className="souvenir-title">{contenu.titre}</div>
            </div>
            {contenu.fichiers && !isEmpty(contenu.fichiers) && (
              <div className="souvenir-fichier">
                <ContenuFilesRender contenu={contenu} />
              </div>
            )}

            {contenu.description && (
              <div className="souvenir-description">{contenu.description}</div>
            )}

            <Form
              onSubmit={handleSubmit}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <Field name="tiroir" validate={required}>
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={valeur => {
                              setTiroirChosen(valeur.value);
                              input.onChange(valeur.value);
                            }}
                            placeholder="Sélectionner le tiroir"
                            name="tiroir"
                            options={tiroirOptions}
                          />
                          <label>Tiroir de destination *</label>
                        </div>
                      );
                    }}
                  </Field>
                  {tiroirChosen && (
                    <Field name="categories" validate={requiredCategories}>
                      {({ input, meta }) => {
                        return (
                          <div className="field">
                            {meta.error && meta.touched && (
                              <div className="field-error">{meta.error}</div>
                            )}

                            <Select
                              className="react-select"
                              classNamePrefix="react-select"
                              onChange={valeurs => {
                                input.onChange(map(valeurs, v => v.value));
                              }}
                              placeholder="Sélectionner la (les) catégorie(s)"
                              isMulti
                              name="categories"
                              options={categoriesOptions}
                            />
                            <label>Catégories *</label>
                          </div>
                        );
                      }}
                    </Field>
                  )}

                  {tiroirChosen === 'ma-vie' && (
                    <Field
                      name="date_contenu"
                      initialValue={contenu.date_contenu || ''}
                      validate={required}
                      component="input"
                      className="field"
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={LocalizedUtils}
                            locale={'fr'}
                          >
                            <ThemeProvider theme={materialTheme}>
                              <KeyboardDatePicker
                                className="datepicker-mui"
                                invalidDateMessage={null}
                                autoOk
                                value={(input && input.value) || null}
                                placeholder="jj/mm/aaaa"
                                okLabel={null}
                                clearLabel={null}
                                clearable={false}
                                cancelLabel={null}
                                onChange={value => {
                                  input.onChange(value);
                                }}
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{ position: 'start' }}
                                inputVariant="outlined"
                                maxDate={new Date()}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                          <label>Date</label>
                        </div>
                      )}
                    </Field>
                  )}
                  {error && <div className="error-block">{error}</div>}
                  <div className="btn-group center">
                    <button
                      onClick={() => {
                        setIsOpen(false);
                      }}
                      className="btn btn-secondary"
                    >
                      <span>Annuler</span>
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                      <span>
                        {loading ? (
                          <ClipLoader size={15} color={'white'} loading={loading} />
                        ) : (
                          'Déplacer'
                        )}
                      </span>
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

ModalMoveContenu.propTypes = {
  moveContenu: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  tiroir: PropTypes.object.isRequired,
  tiroirs: PropTypes.object.isRequired,
  commode: PropTypes.object.isRequired,
  proches: PropTypes.object.isRequired,
  allCategories: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  allCategories: get(state, 'data.entities.categories', {}),
  tiroirs: get(state, 'data.entities.tiroirs', {})
});
export default connect(mapStateToProps, { moveContenu })(ModalMoveContenu);
