import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { version } from '../../../package.json';

import { NavLink, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { register } from '../../store/modules/auth';

import { Form, Field } from 'react-final-form';
import { validateEmail } from '../../utils/StringValidator';
import CheckboxField from '../../components/CheckboxField';
import RadioField from '../../components/RadioField';

import BaseLayout from '../shared/BaseLayout';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

import MomentUtils from '@date-io/moment';

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return moment(date)
      .locale('fr')
      .format('DD MMMM YYYY');
  }
}

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#9445a3'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: 'black'
      }
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#f29200',
          borderRadius: 20
        },
        '&:hover $notchedOutline': {
          borderColor: '#9445a3'
        },
        '&$focused $notchedOutline': {
          borderColor: '#9445a3',
          borderWidth: 1
        },
        '&&& $input': {
          padding: '10px 4px',
          fontSize: '15px'
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: '#9445a3'
      },
      daySelected: {
        backgroundColor: '#9445a3'
      },
      dayDisabled: {
        color: '#9445a3'
      },
      current: {
        color: '#9445a3'
      }
    },
    MuiPickersYear: {
      year: {
        color: 'red'
      },
      yearSelected: {
        color: '#9445a3'
      }
    },
    MuiButton: {
      textPrimary: {
        color: 'black'
      }
    }
  }
});

const InformationCreationClient = ({
  register,
  handleSubmit,
  selectedPack,
  isInscription,
  prevStep,
  nextStep,
  errorProps
}) => {
  const history = useHistory();
  const [error, setError] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const required = value => (value ? undefined : 'Champ requis !');
  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';

  const requiredConditionGenerales = value =>
    value ? undefined : "Merci d'accepter les conditions générales";
  const requiredPaiment = value => (value ? undefined : 'Merci de choisir un mode de paiement');

  useEffect(() => {
    if (errorProps) {
      setError(errorProps);
    }
  }, [errorProps]);

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="flex-1">
          <div className="flex-1">
            <div className="box">
              <div className="box-content">
                <div className="grid">
                  <div className="col-6_sm-12">
                    {' '}
                    <Field name="prenom" validate={required} component="input" className="field">
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" placeholder="Prénom *" />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="col-6_sm-12">
                    <Field validate={required} name="nom" component="input" className="field">
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <input {...input} type="text" placeholder="Nom *" />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="col-12">
                    {' '}
                    <Field
                      validate={requiredEmail}
                      name="email"
                      component="input"
                      className="field"
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {(meta.error && meta.touched) || error ? (
                            <div className="field-error">{meta.error || error}</div>
                          ) : null}
                          <input
                            {...input}
                            type="email"
                            placeholder="Adresse e-mail *"
                            autoCapitalize="none"
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="col-12">
                    <Field
                      // évolution Obendy
                      // validate={required}
                      name="date_naissance"
                      component="input"
                      className="field"
                      initialValue={null}
                    >
                      {({ input, meta }) => (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}
                          <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={LocalizedUtils}
                            locale={'fr'}
                          >
                            <ThemeProvider theme={materialTheme}>
                              <KeyboardDatePicker
                                className="datepicker-mui"
                                invalidDateMessage={null}
                                autoOk
                                value={selectedDate}
                                placeholder="JJ/MM/YYYY"
                                okLabel={null}
                                clearLabel={null}
                                clearable={false}
                                cancelLabel={null}
                                onChange={date => {
                                  input.onChange(date);
                                  setSelectedDate(date);
                                }}
                                format="DD/MM/YYYY"
                                InputAdornmentProps={{ position: 'start' }}
                                inputVariant="outlined"
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                          <label>Date de naissance</label>
                        </div>
                      )}
                    </Field>
                  </div>
                </div>

                <Field name="conditions" type="checkbox" validate={requiredConditionGenerales}>
                  {({ input, meta }) => (
                    <div className="field field-checkbox">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <input id="terms" {...input} type="checkbox" />
                      <label htmlFor="terms">
                        J'accepte les
                        <a className="link" target="_blank" href={`https://www.immortaliz.fr/cgu/`}>
                          conditions générales
                        </a>
                      </label>
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>
          <div className="btn-group center">
            <button type="button" onClick={() => prevStep()} className="btn btn-secondary">
              <i className="fa-solid fa-arrow-left" />
              <span>Précédent</span>
            </button>
            {selectedPack === 1 && isInscription && (
              <button type="submit" className="btn btn-secondary">
                <span>Créer le compte</span>
              </button>
            )}
            {selectedPack === 1 && !isInscription && (
              <button type="submit" className="btn btn-primary">
                <span>Suivant</span>
                <i className="fa-solid fa-arrow-right" />
              </button>
            )}
            {selectedPack === 2 && (
              <button type="submit" className="btn btn-primary">
                <span>Suivant</span>
                <i className="fa-solid fa-arrow-right" />
              </button>
            )}
          </div>
        </form>
      )}
    />
  );
};

InformationCreationClient.propTypes = {
  register: PropTypes.func.isRequired
};

export default connect(null, { register })(InformationCreationClient);
