import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { NavLink, useHistory } from 'react-router-dom';

import { API_URL } from '../constants/Config';

import { connect } from 'react-redux';
import { loadUser } from '../store/modules/auth';
import { createCommode, loadCommodes } from '../store/modules/commodes';
import { loadSettings } from '../store/modules/settings';
import { getProches } from '../store/modules/proches';

import { map, values, filter, find, size, includes } from 'lodash';
import BaseLayout from './shared/BaseLayout';

import ModalConfirm from '../components/modals/ModalConfirm';
import ModalAddProche from '../components/modals/ModalAddProche';
import Modal from 'react-modal';
import modalDefaultClass from '../utils/ModalDefaultClass';

const HomePage = ({
  loadUser,
  getProches,
  loadSettings,
  createCommode,
  user,
  loadCommodes,
  commodes,
  proches
}) => {
  const [modalSuccess, setModalSuccess] = useState(false);
  const [blocageCommode, setBlocageCommode] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Promise.resolve(() => ({}))
      .then(() => {
        if (user) {
          return loadUser(user.user_id);
        }
      })
      .then(() => {
        return loadCommodes(user.user_id);
      })
      .then(res => {
        if (res.response && res.response.entities && res.response.entities.commodes) {
          // on prend les user_id des commodes
          // il faut recuperer les infos des users des commodes (on enlève donc son userId)

          return getProches(
            user.user_id,
            filter(
              map(res.response.entities.commodes, commode => commode.user_id),
              userId => userId !== user.user_id
            )
          );
        } else {
          // cas ou on n'a pas de commode donc on ne charge pas de proche
          return;
        }
      })
      .then(() => {
        loadSettings();
      })
      .then(() => setLoading(false))
      .catch(err => {
        setLoading(false);
        throw String('Erreur lors du chargement des données ', err);
      });
  }, []);

  const history = useHistory();
  const newCommode = () => {
    return createCommode(user.user_id).then(res => {
      setModalSuccess(true);
    });
  };

  const maCommode = find(commodes, c => c.user_id === user.user_id);
  const confirmOk = () => {
    setModalSuccess(false);
    history.push(`/ma-commode/${maCommode.commode_id}`);
  };

  return (
    <BaseLayout isHome isProfil>
      <div className="container center">
        <Modal {...modalDefaultClass} id="modal-confirm" isOpen={modalSuccess}>
          <div className="modal-title">
            Confirmation
            <div onClick={() => confirmOk()} className="btn-close">
              <i className="far fa-times" />
            </div>
          </div>
          <div className="modal-body">
            Votre commode a été créé avec succès, vous pouvez dès à présent partager du contenu.
            <div className="btn-group center">
              <button onClick={() => confirmOk()} className="btn btn-primary">
                <span>OK</span>
              </button>

              <ModalAddProche closeOtherModal={() => setModalSuccess(false)} />
            </div>
          </div>
        </Modal>

        {blocageCommode && (
          <Modal
            className="modal-content"
            overlayClassName="modal-overlay modal-custom"
            id="modal-bloquage-contenu"
            isOpen={blocageCommode}
          >
            <div className="modal-title">
              <div
                onClick={() => {
                  setBlocageCommode(false);
                  // avant, on redirigeait sur l'ajout d'un proche, maintenant on redirige sur la modificatino de l'abonnemnet
                  // du coup, on n'a plus a ouvrir la modal si on ferme la modal avec les infos pour aller sur mode payant
                  // setIsOpen(true);
                }}
                className="btn-close"
              >
                <i className="far fa-times" />
              </div>
            </div>
            <div className="modal-body">
              <i className="fal fa-triangle-exclamation modal-custom-icon" />
              <p>Vous n'avez plus accès à cette commode</p>
            </div>
          </Modal>
        )}

        <div className="home-btn-group">
          {commodes && filter(commodes, c => c.user_id !== user.user_id).length > 0 && (
            <div className="liste-btn-proches">
              {map(
                filter(commodes, c => c.user_id !== user.user_id),
                commode => {
                  let proche = find(proches, proche => proche.user_id === commode.user_id);
                  if (
                    proche &&
                    user &&
                    user.blocked_freemium &&
                    size(user.blocked_freemium) > 0 &&
                    includes(user.blocked_freemium, proche.user_id)
                  ) {
                    proche.blocked = true;
                  }
                  return (
                    <Fragment>
                      {proche && proche.blocked ? (
                        <button
                          key={commode.commode_id}
                          className="btn btn-primary btn-big btn-acces-proche disabled"
                          onClick={() => setBlocageCommode(true)}
                        >
                          <img
                            className="custom-icon"
                            src={require(`../../assets/images/immortaliz-chiffonnier.svg`)}
                          />
                          <div className="proche-infos">
                            <div className="proche-infos-identite">
                              <span>{proche && proche.prenom}</span>
                              <span className="infos-identite-nom">{proche && proche.nom}</span>
                            </div>
                            {proche && proche.profil && (
                              <div className="proche-avatar">
                                <i className="fas fa-lock" />
                              </div>
                            )}
                          </div>
                        </button>
                      ) : (
                        <NavLink
                          key={commode.commode_id}
                          className="btn btn-primary btn-big btn-acces-proche"
                          to={`/commode/${commode.commode_id}`}
                        >
                          <img
                            className="custom-icon"
                            src={require(`../../assets/images/immortaliz-chiffonnier.svg`)}
                          />
                          <div className="proche-infos">
                            <div className="proche-infos-identite">
                              <span>{proche && proche.prenom}</span>
                              <span className="infos-identite-nom">{proche && proche.nom}</span>
                            </div>
                            {proche && proche.profil && (
                              <div className="proche-avatar">
                                <img
                                  src={`${API_URL}/uploads/${proche &&
                                    proche.profil &&
                                    proche.user_id}/${proche.profil}`}
                                />
                              </div>
                            )}
                          </div>
                        </NavLink>
                      )}
                    </Fragment>
                  );
                }
              )}
            </div>
          )}

          {!loading ? (
            maCommode ? (
              <NavLink
                className="btn btn-primary btn-big btn-commode"
                to={`/ma-commode/${maCommode.commode_id}`}
              >
                <img src={require(`../../assets/images/immortaliz-commode.svg`)} />
                <span>Ma commode</span>
              </NavLink>
            ) : (
              <button className="btn btn-primary btn-big btn-commode" onClick={() => newCommode()}>
                <img src={require(`../../assets/images/immortaliz-commode.svg`)} />
                <span>Je crée ma commode</span>
              </button>
            )
          ) : null}

          {maCommode ? (
            <NavLink className="btn btn-secondary btn-big btn-home-proches" to="/mes-proches">
              <img src={require(`../../assets/images/immortaliz-proches.svg`)} />
              <span>Mes proches désignés</span>
            </NavLink>
          ) : (
            <ModalConfirm
              textButton="Mes proches désignés"
              title="Aucune commode !"
              confirmMethode={() => newCommode()}
              confirmText="Je créer ma commode"
              text="Avant de pouvoir ajouter un proche, vous devez créer une commode."
              className="btn btn-secondary btn-big btn-home-proches"
              img={<img src={require(`../../assets/images/immortaliz-proches.svg`)} />}
            />
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

HomePage.propTypes = {
  loadUser: PropTypes.func.isRequired,
  getProches: PropTypes.func.isRequired,
  createCommode: PropTypes.func.isRequired,
  loadCommodes: PropTypes.func.isRequired,
  loadSettings: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  proches: state.data.entities.proches && values(state.data.entities.proches),
  commodes: state.data.entities.commodes && values(state.data.entities.commodes)
});

export default connect(mapStateToProps, {
  loadUser,
  getProches,
  createCommode,
  loadCommodes,
  loadSettings
})(HomePage);
