import React, { useEffect } from 'react';
import { withRouter, BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { requireAuthentication } from '../components/hoc/AuthenticatedComponent';

import HomeAuthPage from './auth/HomeAuthPage';
import WizbiiPage from './auth/WizbiiPage';
import LoginPage from './auth/LoginPage';
import RegisterPage from './auth/RegisterPage';
import AbonnementPage from './auth/AbonnementPage';
import AbonnementUpdatePage from './auth/AbonnementUpdatePage';
import AbonnementUpdateSuccess from './auth/AbonnementUpdateSuccess';
import InscriptionSuccess from './auth/InscriptionSuccess';
import DefinirPasswordPage from './DefinirPasswordPage';
import PasswordOubliePage from './PasswordOubliePage';

import HomePage from './HomePage';
import MaCommodePage from './MaCommodePage';
import CommodeProchePage from './CommodeProchePage';
import MesProchesPage from './MesProchesPage';
import MonComptePage from './MonComptePage';
import SignalerChangement from './SignalerChangement';
import TiroirPage from './TiroirPage';
import MesInfosPersonnelsPage from './MesInfosPersonnelsPage';
import MentionsLegalesPage from './MentionsLegalesPage';
import CheckoutForm from './stripe/CheckoutForm';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const Router = function() {
  return (
    <BrowserRouter>
      <Switch>
        <ScrollToTop>
          <Route exact path="/" component={requireAuthentication(HomePage)} />

          <Route path="/wizbii_redirect" component={WizbiiPage} />
          <Route exact path="/auth" component={HomeAuthPage} />
          <Route exact path="/inscription" component={() => <RegisterPage isInscription />} />
          <Route path="/success" component={() => <InscriptionSuccess />} />
          <Route exact path="/connexion" component={LoginPage} />
          <Route exact path="/mot-de-passe-oubliee" component={PasswordOubliePage} />

          <Route
            exact
            path="/definir-mot-de-passe/:email?/:userid?/:token?"
            component={DefinirPasswordPage}
          />

          <Route exact path="/abonnements" component={requireAuthentication(AbonnementPage)} />
          <Route
            exact
            path="/abonnements/update"
            component={requireAuthentication(AbonnementUpdatePage)}
          />
          <Route
            exact
            path="/abonnements/success"
            component={requireAuthentication(AbonnementUpdateSuccess)}
          />

          <Route
            exact
            path="/ma-commode/:commodeId"
            component={requireAuthentication(MaCommodePage)}
          />
          <Route
            exact
            path="/commode/:commodeId"
            component={requireAuthentication(CommodeProchePage)}
          />
          <Route
            exact
            path="/commode/:commodeId/signaler-changement"
            component={requireAuthentication(SignalerChangement)}
          />
          <Route exact path="/mes-proches" component={requireAuthentication(MesProchesPage)} />
          <Route
            exact
            path="/tiroir/:tiroirId/:tiroirName"
            component={requireAuthentication(TiroirPage)}
          />

          <Route exact path="/mon-compte" component={requireAuthentication(MonComptePage)} />
          <Route
            exact
            path="/mes-infos-personnelles"
            component={requireAuthentication(MesInfosPersonnelsPage)}
          />
          <Route
            exact
            path="/mentions-legales"
            component={requireAuthentication(MentionsLegalesPage)}
          />
        </ScrollToTop>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
