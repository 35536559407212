import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { getProches } from '../store/modules/proches';
import { getTiroirs } from '../store/modules/tiroirs';
import { createCommode } from '../store/modules/commodes';

import { map, values, find, includes, filter, size } from 'lodash';

import BaseLayout from './shared/BaseLayout';

import ModalAddProche from '../components/modals/ModalAddProche';
import ModalConfirm from '../components/modals/ModalConfirm';

import ProcheItem from '../components/ProcheItem';

const MesProchesPage = ({ proches, getProches, getTiroirs, user, commodes, createCommode }) => {
  const history = useHistory();

  const maCommode = find(commodes, c => c.user_id === user.user_id);

  useEffect(() => {
    if (maCommode) {
      getTiroirs(user.user_id, maCommode.commode_id);
    }
    getProches(user.user_id);
  }, []);

  const newCommode = () => {
    return createCommode(user.user_id).then(res => {
      history.push(`/ma-commode/${values(res.response.entities.commodes)[0].commode_id}`);
    });
  };

  return (
    <BaseLayout headerPageTitle="Mes proches" isBack>
      <div className="container alignCenter">
        <div className="content">
          {proches && proches.length > 0 ? (
            map(proches, proche => {
              return (
                <ProcheItem
                  key={proche.user_id}
                  proche={proche}
                  blocked={
                    user &&
                    user.user_id &&
                    proche &&
                    proche.blocked_freemium &&
                    size(proche.blocked_freemium) > 0 &&
                    includes(proche.blocked_freemium, user.user_id)
                      ? true
                      : false
                  }
                />
              );
            })
          ) : (
            <div className="consignes">
              Mes proches désignés sont les personnes autorisées à accéder à ma commode Immortaliz
              avant ou après mon ultime voyage et à ma guise.
              <br />
              <br />
              Je peux les modifier à tout moment et changer leurs accès aux différents tiroirs de ma
              commode.
            </div>
          )}
        </div>
        {maCommode ? (
          <ModalAddProche />
        ) : (
          <ModalConfirm
            textButton="J'ajoute un proche"
            title="Aucune commode !"
            confirmMethode={() => newCommode()}
            confirmText="Je créer ma commode"
            text="Avant de pouvoir ajouter un proche, vous devez créer une commode."
          />
        )}
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    mesTiroirs: state.data.entities.tiroirs && values(state.data.entities.tiroirs),
    proches: filter(state.data.entities.proches, p =>
      includes(p.parrain_id, state.auth.user.user_id)
    ),
    commodes: state.data.entities.commodes && values(state.data.entities.commodes)
  };
};

MesProchesPage.propTypes = {
  user: PropTypes.object.isRequired,
  getProches: PropTypes.func.isRequired,
  getTiroirs: PropTypes.func.isRequired,
  createCommode: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { getProches, createCommode, getTiroirs })(MesProchesPage);
