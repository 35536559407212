import React from 'react';
import { NavLink } from 'react-router-dom';

import { version } from '../../../package.json';

import BaseLayout from '../shared/BaseLayout';

const HomeAuthPage = () => {
  return (
    <BaseLayout isLogin classScreen="auth login">
      <div className="container">
        <div className="logo-block flex-1">
          <img src={require(`../../../assets/images/logo-immortaliz-vertical.svg`)} />
          <div className="title">Transmettez à vos proches ce qui vous tient le plus à cœur</div>
        </div>

        <div className="auth-buttons">
          <NavLink to="/inscription" className="btn btn-primary">
            Je crée mon compte
          </NavLink>
          <NavLink to="/connexion" className="btn btn-primary">
            Je me connecte
          </NavLink>
          <a className="link" href="https://www.immortaliz.fr/" target="_blank" rel="noopener">
            www.immortaliz.fr
          </a>

          <span className="version">
            <i className="far fa-laptop-code" />
            Version {version}
          </span>
        </div>
      </div>
    </BaseLayout>
  );
};

export default HomeAuthPage;
