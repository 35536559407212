import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';
import { schema } from 'normalizr';

import { CALL_API, Schemas } from '../Schemas';

const LOAD_COMMODES_REQUEST = 'immowa/LOAD_COMMODES_REQUEST';
const LOAD_COMMODES_SUCCESS = 'immowa/LOAD_COMMODES_SUCCESS';
const LOAD_COMMODES_FAILURE = 'immowa/LOAD_COMMODES_FAILURE';

const CREATE_COMMODE_REQUEST = 'immowa/CREATE_COMMODE_REQUEST';
const CREATE_COMMODE_SUCCESS = 'immowa/CREATE_COMMODE_SUCCESS';
const CREATE_COMMODE_FAILURE = 'immowa/CREATE_COMMODE_FAILURE';

export const commodesActionsHandlers = {
  [LOAD_COMMODES_SUCCESS]: (state, action) =>
    flow(
      set('entities.commodes', action.response.entities.commodes || {}),
      set('loaded.commodes', true),
      set('loading.commodes', false)
    )(state),

  [CREATE_COMMODE_SUCCESS]: (state, action) => {
    return flow(
      set('entities.commodes', {
        ...state.entities.commodes,
        ...action.response.entities.commodes
      }),
      set('entities.tiroirs', {
        ...state.entities.tiroirs,
        ...action.response.entities.tiroirs
      }),
      set('loaded.commodes', true),
      set('loading.commodes', false)
    )(state);
  }
};

export function createCommode(userId) {
  return {
    [CALL_API]: {
      types: [CREATE_COMMODE_REQUEST, CREATE_COMMODE_SUCCESS, CREATE_COMMODE_FAILURE],
      method: 'POST',
      endpoint: '/commodes',
      schema: Schemas.COMMODE_TIROIR_ARRAY,
      body: { user_id: userId }
    }
  };
}

export function loadCommodes(userId) {
  return {
    [CALL_API]: {
      types: [LOAD_COMMODES_REQUEST, LOAD_COMMODES_SUCCESS, LOAD_COMMODES_FAILURE],
      method: 'GET',
      endpoint: `/commodes/${userId}`,
      schema: Schemas.COMMODE_ARRAY
    }
  };
}
