import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { map, isUndefined } from 'lodash';
import { getTiroirs } from '../store/modules/tiroirs';
import { getCategories } from '../store/modules/categories';
import BaseLayout from './shared/BaseLayout';
import TiroirItem from '../components/TiroirItem';

const MaCommodePage = ({ getTiroirs, getCategories, user, commodes, tiroirs }) => {
  let { commodeId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (isUndefined(commodes[commodeId])) {
      return history.push('/');
    } else {
      // 5389 charger les catégories propres à la commode
      getCategories(commodeId);
      getTiroirs(user.user_id, commodeId);
    }
  }, []);
  return (
    <BaseLayout headerPageTitle="Ma commode" classScreen="commode" isCommode isProfil>
      <div className="container justifyEvenly alignCenter">
        {map(tiroirs, tiroir => {
          return (
            <TiroirItem
              key={tiroir.tiroir_id}
              tiroir={tiroir}
              special={tiroir.value === 'postez-moi' ? true : false}
            />
          );
        })}
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  tiroirs: state.data.entities.tiroirs,
  commodes: state.data.entities.commodes
});

MaCommodePage.propTypes = {
  tiroirs: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getTiroirs: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { getTiroirs, getCategories })(MaCommodePage);
