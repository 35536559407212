import React from 'react';

const RadioField = ({
  id,
  input,
  name,
  meta,
  label,
  icon,
  iconFamily,
  customRadio,
  disabled,
  onClick,
  updateProject,
  noError
}) => {
  //fix
  return (
    <div className={`field ${customRadio ? 'radio-custom' : 'radio'}`}>
      {!noError && meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
      <input
        id={id}
        {...input}
        disabled={disabled}
        onClick={e => {
          return onClick && onClick(e.target.value);
        }}
      />
      <label htmlFor={id}>
        <span className="radio-input" />
        {icon && <i className={`radio-icon ${iconFamily} ${icon}`} />}
        <span className="radio-label">{label}</span>
      </label>
    </div>
  );
};

export default RadioField;
