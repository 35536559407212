import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import { map, find, values, filter, pull, clone } from 'lodash';
import { updateDroitsTiroirs, getTiroirs } from '../../store/modules/tiroirs';
import { getDroitTiroirUser } from '../../utils/methodes';
import modalDefaultClass from '../../utils/ModalDefaultClass';
import { ACCES_TIROIRS } from '../../constants/Properties';

const ModalEditDroitsAccesProche = ({
  getTiroirs,
  maCommode,
  tiroirs,
  updateDroitsTiroirs,
  user,
  proche
}) => {
  useEffect(() => {
    getTiroirs(user.user_id, maCommode.commode_id);
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');

  const optionsAcces = map(ACCES_TIROIRS, (item, key) => {
    return { value: key, label: item };
  });

  const optionsAccesLimite = pull(clone(optionsAcces), optionsAcces[2]);

  const handleSubmit = values => {
    const data = values;

    const tiroirsUpdate = [
      {
        tiroirId: find(tiroirs, t => t.value === 'ma-vie').tiroir_id,
        userId: proche.user_id,
        proches: find(tiroirs, t => t.value === 'ma-vie').proches,
        newDroit: data.ma_vie
      },
      {
        tiroirId: find(tiroirs, t => t.value === 'a-partager').tiroir_id,
        userId: proche.user_id,
        proches: find(tiroirs, t => t.value === 'a-partager').proches,
        newDroit: data.a_partager
      },
      {
        tiroirId: find(tiroirs, t => t.value === 'mes-directives').tiroir_id,
        userId: proche.user_id,
        proches: find(tiroirs, t => t.value === 'mes-directives').proches,
        newDroit: data.mes_directives
      },
      {
        tiroirId: find(tiroirs, t => t.value === 'administratif').tiroir_id,
        userId: proche.user_id,
        proches: find(tiroirs, t => t.value === 'administratif').proches,
        newDroit: data.administratif
      },
      {
        tiroirId: find(tiroirs, t => t.value === 'mon-depart').tiroir_id,
        userId: proche.user_id,
        proches: find(tiroirs, t => t.value === 'mon-depart').proches,
        newDroit: data.mon_depart
      }
      // {
      //   tiroirId: find(tiroirs, t => t.value === 'postez-moi').tiroir_id,
      //   userId: proche.user_id,
      //   proches: find(tiroirs, t => t.value === 'postez-moi').proches,
      //   newDroit: data.postez_moi
      // }
    ];

    return updateDroitsTiroirs(tiroirsUpdate, maCommode.commode_id)
      .then(() => {
        setIsOpen(false);
        setError(false);
      })
      .catch(err => setError(err));
  };

  return (
    <Fragment>
      <button
        className="btn"
        onClick={() => {
          setError(null);
          setIsOpen(!isOpen);
        }}
      >
        <i className="far fa-pen" />
      </button>
      <Modal {...modalDefaultClass} id="modal-contenu" isOpen={isOpen}>
        <div className="modal-title">
          Modifier les droits d'accès aux tiroirs
          <div onClick={() => setIsOpen(false)} className="btn-close">
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  validate={required}
                  name="ma_vie"
                  component="select"
                  initialValue={getDroitTiroirUser(tiroirs, 'ma-vie', proche.user_id)}
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <Select
                          options={optionsAcces}
                          onChange={option => {
                            input.onChange(option.value);
                          }}
                          value={find(optionsAcces, o => o.value === input.value)}
                        />

                        <label>L'album de ma vie</label>
                      </div>
                    );
                  }}
                </Field>
                <Field
                  validate={required}
                  name="a_partager"
                  component="select"
                  initialValue={getDroitTiroirUser(tiroirs, 'a-partager', proche.user_id)}
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <Select
                          options={optionsAcces}
                          onChange={option => {
                            input.onChange(option.value);
                          }}
                          value={find(optionsAcces, o => o.value === input.value)}
                        />

                        <label>Ce que je veux partager</label>
                      </div>
                    );
                  }}
                </Field>
                <Field
                  validate={required}
                  name="mes_directives"
                  component="select"
                  initialValue={getDroitTiroirUser(tiroirs, 'mes-directives', proche.user_id)}
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <Select
                          options={optionsAcces}
                          onChange={option => {
                            input.onChange(option.value);
                          }}
                          value={find(optionsAcces, o => o.value === input.value)}
                        />

                        <label>Mes souhaits en cas d’urgence</label>
                      </div>
                    );
                  }}
                </Field>
                <Field
                  validate={required}
                  name="administratif"
                  component="select"
                  initialValue={getDroitTiroirUser(tiroirs, 'administratif', proche.user_id)}
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <Select
                          options={optionsAcces}
                          onChange={option => {
                            input.onChange(option.value);
                          }}
                          value={find(optionsAcces, o => o.value === input.value)}
                        />

                        <label>Mes documents administratifs</label>
                      </div>
                    );
                  }}
                </Field>
                <Field
                  validate={required}
                  name="mon_depart"
                  component="select"
                  initialValue={getDroitTiroirUser(tiroirs, 'mon-depart', proche.user_id)}
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <Select
                          options={optionsAcces}
                          onChange={option => {
                            input.onChange(option.value);
                          }}
                          value={find(optionsAcces, o => o.value === input.value)}
                        />

                        <label>Mon départ</label>
                      </div>
                    );
                  }}
                </Field>
                {/* <Field
                  validate={required}
                  name="postez_moi"
                  component="select"
                  initialValue={getDroitTiroirUser(tiroirs, 'postez-moi', proche.user_id)}
                >
                  {({ input, meta }) => {
                    return (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}

                        <Select
                          options={optionsAccesLimite}
                          onChange={option => {
                            input.onChange(option.value);
                          }}
                          value={find(optionsAcces, o => o.value === input.value)}
                        />

                        <label>Postez-moi un message</label>
                      </div>
                    );
                  }}
                </Field> */}
                {error && <div className="error-block">{error}</div>}
                <div className="btn-group center">
                  <button type="submit" className="btn btn-primary">
                    <span>Enregistrer</span>
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

ModalEditDroitsAccesProche.propTypes = {
  updateDroitsTiroirs: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getTiroirs: PropTypes.func.isRequired,
  tiroirs: PropTypes.array.isRequired,
  proche: PropTypes.object.isRequired,
  maCommode: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  maCommode:
    state.data.entities.commodes &&
    filter(
      values(state.data.entities.commodes),
      commode => commode.user_id === state.auth.user.user_id
    )[0],
  tiroirs: state.data.entities.tiroirs && values(state.data.entities.tiroirs)
});

export default connect(mapStateToProps, { updateDroitsTiroirs, getTiroirs })(
  ModalEditDroitsAccesProche
);
