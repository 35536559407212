import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { map, find, values, filter, orderBy, isEmpty } from 'lodash';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import { deleteContenu, updateContenu } from '../store/modules/contenus';
import ModalFormContenu from './modals/ModalFormContenu';
import ModalConfirm from './modals/ModalConfirm';
import ContenuFilesRender from './shared/ContenuFilesRender';
import { generateHexString } from '../utils/key';
import { API_URL } from '../constants/Config';
moment.locale('fr');

const ContenuCardTimeline = ({
  user,
  proches,
  contenu,
  tiroir,
  categories,
  deleteContenu,
  updateContenu,
  isAuthor
}) => {
  const [error, setError] = useState(null);
  const [openFormComment, setOpenFormComment] = useState(false);
  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    const data = values;

    data.tiroir_id = tiroir.tiroir_id;
    data.commentaires = [
      ...contenu.commentaires,
      {
        id: generateHexString(10),
        user_id: user.user_id,
        date: new Date(),
        commentaire: data.commentaire
      }
    ];

    return updateContenu(contenu, data, user.user_id)
      .then(() => setOpenFormComment(false))
      .catch(err => setError(err));
  };

  const deleteCommentaire = id => {
    return updateContenu(
      contenu,
      {
        commentaires: filter(contenu.commentaires, com => com.id !== id)
      },
      user.user_id
    )
      .then(() => setOpenFormComment(false))
      .catch(err => setError(err));
  };

  return (
    <div key={contenu.contenu_id} className="souvenir souvenir-ma_vie">
      <div className="souvenir-header">
        <div className="souvenir-date">
          <div>
            <div className="souvenir-date-day">{moment(contenu.date_contenu).format('ddd DD')}</div>
            <div className="souvenir-date-monthyear">
              {moment(contenu.date_contenu).format('MMM YYYY')}
            </div>
          </div>
        </div>
        <div className="souvenir-title">{contenu.titre}</div>
      </div>

      {contenu.fichiers && !isEmpty(contenu.fichiers) && <ContenuFilesRender contenu={contenu} />}

      {contenu.description && <div className="souvenir-description">{contenu.description}</div>}

      <div className="commentaire-block">
        <div className="souvenir-commentaires-block">
          {map(orderBy(contenu.commentaires, ['date'], ['asc']), (com, index) => {
            const userCom =
              (com.user_id === user.user_id && user) ||
              find(values(proches), proche => proche.user_id === com.user_id);

            const isAuthor = com.user_id === user.user_id;
            const isProprietaire = contenu.user_id === user.user_id;

            const deleteAuthorize = isAuthor || isProprietaire;

            return (
              userCom && (
                <div className="souvenir-commentaire" key={index}>
                  {deleteAuthorize && (
                    <ModalConfirm
                      textButton={<i className="fas fa-times" />}
                      title="Supprimer ce commentaire"
                      className="btn-close"
                      confirmMethode={() => deleteCommentaire(com.id)}
                      text="Etes-vous sûr de vouloir supprimer ce commentaire ? "
                    />
                  )}

                  <div className="souvenir-commentaire-avatar">
                    {(userCom && userCom.profil && (
                      <img
                        src={`${API_URL}/uploads/${userCom.user_id}/${(userCom &&
                          userCom.profil &&
                          userCom.profil) ||
                          ''}`}
                      />
                    )) || (
                      <Fragment>
                        {userCom.prenom && userCom.prenom[0]}
                        {userCom.nom && userCom.nom[0]}
                      </Fragment>
                    )}
                  </div>
                  <div className="souvenir-commentaire-content">
                    <div className="souvenir-commentaire-author">
                      {userCom.prenom} {userCom && userCom.nom}
                    </div>
                    <div className="souvenir-commentaire-text">{com.commentaire}</div>
                    <div className="souvenir-commentaire-date">
                      {moment(com.date).format('ll')}, {moment(com.date).format('LT')}
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
        {openFormComment && (
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field name="commentaire" validate={required} component="input" className="field">
                  {({ input, meta }) => (
                    <div className="field">
                      {meta.error && meta.touched && (
                        <div className="field-error">{meta.error}</div>
                      )}
                      <textarea {...input} rows="6" />
                      <label>Commentaire *</label>
                    </div>
                  )}
                </Field>

                {error && <div className="error-block">{error}</div>}
                <div className="btn-group center">
                  <button onClick={() => setOpenFormComment(false)} className="btn btn-secondary">
                    <span>Annuler</span>
                  </button>
                  <button type="submit" className="btn btn-primary">
                    <span>Ajouter</span>
                  </button>
                </div>
              </form>
            )}
          />
        )}
        <div className="souvenir-actions">
          <button className="btn" onClick={() => setOpenFormComment(true)}>
            <i className="fas fa-comment-alt-lines" />
            <span>Commenter</span>
          </button>
          {isAuthor && (
            <Fragment>
              <ModalFormContenu isEdit contenu={contenu} tiroir={tiroir} categories={categories} />
              <ModalConfirm
                textButton={<i className="far fa-trash-alt" />}
                title="Supprimer ce contenu"
                className="btn"
                confirmMethode={() => deleteContenu(contenu)}
                text="Etes-vous sûr de vouloir supprimer ce contenu ? "
              />
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

ContenuCardTimeline.propTypes = {
  isAuthor: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  contenu: PropTypes.object.isRequired,
  tiroir: PropTypes.object.isRequired,
  proches: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  updateContenu: PropTypes.func.isRequired,
  deleteContenu: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  user: state.auth.user,
  proches: state.data.entities.proches
});

export default connect(mapStateToProps, { deleteContenu, updateContenu })(ContenuCardTimeline);
