import { flow, set } from 'lodash/fp';
import { filter, omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

import { getTiroirs } from './tiroirs';

const ADD_PROCHE_REQUEST = 'immowa/ADD_PROCHE_REQUEST';
const ADD_PROCHE_SUCCESS = 'immowa/ADD_PROCHE_SUCCESS';
const ADD_PROCHE_FAILURE = 'immowa/ADD_PROCHE_FAILURE';

const GET_USER_BY_EMAIL_REQUEST = 'immowa/GET_USER_BY_EMAIL_REQUEST';
const GET_USER_BY_EMAIL_SUCCESS = 'immowa/GET_USER_BY_EMAIL_SUCCESS';
const GET_USER_BY_EMAIL_FAILURE = 'immowa/GET_USER_BY_EMAIL_FAILURE';

const GET_PROCHES_REQUEST = 'immowa/GET_PROCHES_REQUEST';
const GET_PROCHES_SUCCESS = 'immowa/GET_PROCHES_SUCCESS';
const GET_PROCHES_FAILURE = 'immowa/GET_PROCHES_FAILURE';

const DELETE_PROCHE_REQUEST = 'immowa/DELETE_PROCHE_REQUEST';
const DELETE_PROCHE_SUCCESS = 'immowa/DELETE_PROCHE_SUCCESS';
const DELETE_PROCHE_FAILURE = 'immowa/DELETE_PROCHE_FAILURE';

const DESENGAGMENT_REQUEST = 'immowa/DESENGAGMENT_REQUEST';
const DESENGAGMENT_SUCCESS = 'immowa/DESENGAGMENT_SUCCESS';
const DESENGAGMENT_FAILURE = 'immowa/DESENGAGMENT_FAILURE';

const SIGNALEMENT_DECES_REQUEST = 'immowa/SIGNALEMENT_DECES_REQUEST';
const SIGNALEMENT_DECES_SUCCESS = 'immowa/SIGNALEMENT_DECES_SUCCESS';
const SIGNALEMENT_DECES_FAILURE = 'immowa/SIGNALEMENT_DECES_FAILURE';

export const prochesActionsHandlers = {
  [GET_PROCHES_SUCCESS]: (state, action) => {
    return flow(
      set(
        'entities.proches',
        { ...state.entities.proches, ...action.response.entities.proches } || {}
      ),
      set('loaded.proches', true),
      set('loading.proches', false)
    )(state);
  },

  [ADD_PROCHE_SUCCESS]: (state, action) => {
    return flow(
      set('entities.proches', {
        ...state.entities.proches,
        ...action.response.entities.proches
      }),
      set('loaded.proches', true),
      set('loading.proches', false)
    )(state);
  },
  [DELETE_PROCHE_SUCCESS]: (state, action) => {
    return flow(
      set(
        'entities.proches',
        filter(state.entities.proches, p => p.user_id !== action.proche.user_id)
      )
    )(state);
  },
  [DESENGAGMENT_SUCCESS]: (state, action) => {
    return flow(set('entities.proches', omit(state.entities.proches, action.parrain.user_id)))(
      state
    );
  }
};

export function createProche(data, commodeId) {
  return {
    [CALL_API]: {
      types: [ADD_PROCHE_REQUEST, ADD_PROCHE_SUCCESS, ADD_PROCHE_FAILURE],
      method: 'POST',
      endpoint: '/users/proche',
      body: data,
      schema: Schemas.PROCHE,
      successNext: res => {
        return getTiroirs(data.parrain.user_id, commodeId);
      }
    }
  };
}

export function getUserByEmail(email) {
  return {
    [CALL_API]: {
      types: [GET_USER_BY_EMAIL_REQUEST, GET_USER_BY_EMAIL_SUCCESS, GET_USER_BY_EMAIL_FAILURE],
      method: 'GET',
      endpoint: `/users/user/${email}`
    }
  };
}

export function getProches(userId, usersIdCommode) {
  return {
    userId,
    [CALL_API]: {
      types: [GET_PROCHES_REQUEST, GET_PROCHES_SUCCESS, GET_PROCHES_FAILURE],
      method: 'POST',
      endpoint: `/users/proches/${userId}`,
      body: {
        usersIdCommode
      },
      schema: Schemas.PROCHE_ARRAY
    }
  };
}

export function deleteProche(user, proche, isParrain, commodeId) {
  return {
    proche,
    [CALL_API]: {
      types: [DELETE_PROCHE_REQUEST, DELETE_PROCHE_SUCCESS, DELETE_PROCHE_FAILURE],
      method: 'DELETE',
      body: { user, proche, isParrain },
      endpoint: `/users/proche`,
      successMessage: 'Votre proche a bien été supprimé',
      successNext: res => {
        return getTiroirs(user.user_id, commodeId);
      }
    }
  };
}

export function desengagmentUser(user, parrain, commodeId) {
  return {
    parrain,
    [CALL_API]: {
      types: [DESENGAGMENT_REQUEST, DESENGAGMENT_SUCCESS, DESENGAGMENT_FAILURE],
      method: 'DELETE',
      body: { user, parrain, commodeId },
      endpoint: `/users/proche/desengagment`,
      successMessage: 'Désengagement réussi',
      successNext: res => {
        return getTiroirs(data.parrain.user_id, commodeId);
      }
    }
  };
}

export function signalementDeces(user, parrain) {
  return {
    [CALL_API]: {
      types: [SIGNALEMENT_DECES_REQUEST, SIGNALEMENT_DECES_SUCCESS, SIGNALEMENT_DECES_FAILURE],
      method: 'POST',
      body: { user, parrain },
      endpoint: `/users/proche/deces`,
      successMessage: 'Signalement de décès réussi'
    }
  };
}
