import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import ReactPlayer from 'react-player/lazy';

import { map, filter, includes, isEmpty, union } from 'lodash';
import { API_URL } from '../../constants/Config';
import { removeExtension, getExtension } from '../../utils/StringUtils';
import { renderDocIcon } from '../../utils/methodes';

const ContenuFilesRender = ({ contenu }) => {
  const [slideSelected, setSlideSelected] = useState(0);

  const updateCurrentSlide = index => {
    setSlideSelected(index);
  };

  const filesContenu = map(contenu.fichiers, fichier => fichier);

  const imagesFiles = filter(filesContenu, f => f && f.fileType && f.fileType === 'image');
  const videoFiles = filter(filesContenu, f => f && f.fileType && f.fileType === 'video');

  const audioFiles = filter(filesContenu, f => f && f.fileType && f.fileType === 'audio');
  const documentsFiles = filter(
    filesContenu,
    f =>
      f &&
      f.fileType &&
      includes(
        [
          'pdf',
          'texte',
          'microsoft_word',
          'microsoft_excel',
          'microsoft_powerpoint',
          'open_office'
        ],
        f.fileType
      )
  );
  const imagesVideos = union(imagesFiles, videoFiles);

  return (
    <div className="souvenir-fichier">
      {!isEmpty(imagesVideos) &&
        (imagesVideos.length > 1 ? (
          <Carousel
            infiniteLoop={false}
            autoFocus={true}
            showThumbs={false}
            showStatus={false}
            showArrows={true}
            useKeyboardArrows={false}
            autoPlay={false}
            selectedItem={slideSelected}
            interval={1e11}
            onChange={index => updateCurrentSlide(index)}
          >
            {map(
              filter(imagesVideos, i => i.fileType === 'video'),
              (v, index) => (
                <div className="player-wrapper" key={index}>
                  <ReactPlayer
                    controls
                    className="react-player"
                    type="video/mp4"
                    url={`${API_URL}/uploads/${contenu.user_id}/${(contenu &&
                      v.fileName &&
                      'min_' + v.fileName) ||
                      ''}`}
                  />
                </div>
              )
            )}
            {map(
              filter(imagesVideos, i => i.fileType === 'image'),
              (i, index) => (
                <img
                  key={index}
                  srcSet={map(
                    ['640w', '1024w', '1920w'],
                    taille =>
                      `${API_URL}/uploads/${contenu.user_id}/${removeExtension(
                        i.fileName
                      )}-${taille}${getExtension(i.fileName)} ${taille}`
                  )}
                />
              )
            )}
          </Carousel>
        ) : (
          (imagesVideos[0].fileType === 'video' && (
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                type="video/mp4"
                controls
                url={`${API_URL}/uploads/${contenu.user_id}/${(contenu &&
                  imagesVideos[0].fileName &&
                  'min_' + imagesVideos[0].fileName) ||
                  ''}`}
              />
            </div>
          )) || (
            <img
              srcSet={map(
                ['640w', '1024w', '1920w'],
                taille =>
                  `${API_URL}/uploads/${contenu.user_id}/${removeExtension(
                    imagesVideos[0].fileName
                  )}-${taille}${getExtension(imagesVideos[0].fileName)} ${taille}`
              )}
            />
          )
        ))}

      {!isEmpty(documentsFiles) &&
        map(documentsFiles, (d, index) => {
          return (
            <div key={index} className="souvenir-link-block">
              <a
                className="link"
                target="_blank"
                href={`${API_URL}/uploads/${contenu.user_id}/${d.fileName}`}
              >
                <i className={`fas fa-${renderDocIcon(d.fileType)}`} />
                {d.fileName.substring(d.fileName.indexOf('-') + 8)}
              </a>
            </div>
          );
        })}
      {!isEmpty(audioFiles) &&
        map(audioFiles, (a, index) => {
          return (
            <figure key={index}>
              <audio
                controls
                src={`${API_URL}/uploads/${contenu.user_id}/${(contenu &&
                  a.fileName &&
                  a.fileName) ||
                  ''}`}
              />
            </figure>
          );
        })}
    </div>
  );
};

export default ContenuFilesRender;
