import React from 'react';
export default ({
  id,
  description,
  input,
  customCheckbox,
  meta,
  label,
  icon,
  iconFamily,
  sublabel,
  onChange,
  onClick
}) => {
  return (
    <div key={id} className={`field ${customCheckbox ? 'checkbox-custom' : 'checkbox'}`}>
      {meta && meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
      <input id={id} {...input} />
      <label htmlFor={id}>
        <span className="checkbox-input" />
        {icon && <i className={`checkbox-icon ${iconFamily} ${icon}`} />}
        <span className="checkbox-label">
          <span dangerouslySetInnerHTML={{ __html: label }} />
          {sublabel && <span className="checkbox-sublabel">{sublabel}</span>}
          {description && <span className="checkbox-description">{description}</span>}
        </span>
      </label>
    </div>
  );
};
