import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createCategorie, updateCategorie } from '../../store/modules/categories';

import modalDefaultClass from '../../utils/ModalDefaultClass';

import Modal from 'react-modal';

import { Form, Field } from 'react-final-form';

import ClipLoader from 'react-spinners/ClipLoader';
import { useParams } from 'react-router';

const ModalAddCategorie = ({
  isEdit,
  categorie,
  categories,
  tiroirs,
  isOpen = false,
  setIsOpen,
  createCategorie,
  setSelectedFilter,
  updateCategorie
}) => {
  const { tiroirName, tiroirId } = useParams();
  const [loading, setLoading] = useState(false);

  const [modalBlocage, setModalBlocage] = useState(false);

  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    let data = {};
    if (isEdit) {
      data = { ...categorie };
    }

    data = { ...data, ...values };
    if (isEdit) {
      /*
      -----------------------------------------------
      EDITION D'UN CATEGORIE
      -----------------------------------------------
      */

      setLoading(true);

      return Promise.resolve(() => 'good')
        .then(() => {
          return updateCategorie(categories[tiroirName], data)
            .then(() => {
              setSelectedFilter(1);
              setIsOpen();

              setLoading(false);
            })
            .catch(err => {
              setError(err);
              setLoading(false);
            });
        })
        .catch(err => {
          console.log('err', err);
          setError(err);
        });
    } else {
      /*
      -----------------------------------------------
      CREATION D'UN CATEGORIE
      -----------------------------------------------
      */
      setLoading(true);

      return createCategorie({
        ...data,
        commode_id: tiroirs[tiroirId].commode_id,
        slug: tiroirName
      })
        .then(res => {
          setIsOpen(false);
          setLoading(false);
        })
        .catch(err => {
          setError(err);
          setLoading(false);
        });
    }
  };
  return (
    <Fragment>
      {modalBlocage && (
        <Modal {...modalDefaultClass} id="modal-bloquage-categorie" isOpen={modalBlocage}>
          <div className="modal-title">
            Blocage categorie
            <div onClick={() => setModalBlocage(false)} className="btn-close">
              <i className="far fa-times" />
            </div>
          </div>
          <div className="modal-body">
            BLOCAGE CATEGORIE
            <div className="btn-group center">
              <button onClick={() => setModalBlocage()} className="btn btn-primary">
                <span>OK</span>
              </button>
            </div>
          </div>
        </Modal>
      )}

      {isOpen && (
        <Modal
          className="modal-content"
          overlayClassName="modal-overlay modal-classic"
          id="modal-categorie"
          isOpen={typeof isOpen === 'boolean' ? isOpen : false}
        >
          <div className="modal-title">
            {(isEdit && 'Modifier la catégorie') || 'Ajouter une catégorie'}
            <div
              onClick={() => {
                setIsOpen();
              }}
              className="btn-close"
            >
              <i className="far fa-times" />
            </div>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={handleSubmit}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="label"
                    initialValue={(isEdit && categorie && categorie.label) || ''}
                    validate={required}
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" placeholder="Nom de la catégorie" />
                      </div>
                    )}
                  </Field>

                  {error && <div className="error-block">{error}</div>}
                  <div className="btn-group center">
                    <button
                      onClick={() => setIsOpen()}
                      className="btn btn-secondary"
                      disabled={loading}
                    >
                      <span>Annuler</span>
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                      <span>
                        {loading ? (
                          <ClipLoader size={15} color={'white'} loading={loading} />
                        ) : (
                          (isEdit && 'Modifier') || 'Ajouter'
                        )}
                      </span>
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

ModalAddCategorie.propTypes = {
  createCategorie: PropTypes.func.isRequired,
  updateCategorie: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    tiroirs: state.data.entities.tiroirs,
    categories: state.data.entities.categories,
    user: state.auth.user
  };
};
export default connect(mapStateToProps, {
  createCategorie,
  updateCategorie
})(ModalAddCategorie);
