import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import moment from 'moment';

import Router from './containers/Router';
import configureStore from './store/configureStore';

moment.locale('fr');
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../assets/stylesheets/app.scss';

// Import Favicons directory
import '../assets/favicons/manifest-icon-192.png';
import '../assets/favicons/manifest-icon-512.png';
import '../assets/favicons/maskable_icon.png';
import '../assets/favicons/apple-touch-icon-180.png';
import '../assets/favicons/browserconfig.xml';
import '../assets/favicons/favicon-16x16.png';
import '../assets/favicons/favicon-32x32.png';
import '../assets/favicons/favicon.ico';
import '../assets/favicons/site.webmanifest';
import '../assets/favicons/mstile-150x150.png';
import '../assets/favicons/safari-pinned-tab.svg';

import '../assets/splash/apple-splash-2048-2732.jpg';
import '../assets/splash/apple-splash-2732-2048.jpg';
import '../assets/splash/apple-splash-1668-2388.jpg';
import '../assets/splash/apple-splash-2388-1668.jpg';
import '../assets/splash/apple-splash-1536-2048.jpg';
import '../assets/splash/apple-splash-2048-1536.jpg';
import '../assets/splash/apple-splash-1668-2224.jpg';
import '../assets/splash/apple-splash-2224-1668.jpg';
import '../assets/splash/apple-splash-1620-2160.jpg';
import '../assets/splash/apple-splash-2160-1620.jpg';
import '../assets/splash/apple-splash-1284-2778.jpg';
import '../assets/splash/apple-splash-2778-1284.jpg';
import '../assets/splash/apple-splash-1170-2532.jpg';
import '../assets/splash/apple-splash-2532-1170.jpg';
import '../assets/splash/apple-splash-1125-2436.jpg';
import '../assets/splash/apple-splash-2436-1125.jpg';
import '../assets/splash/apple-splash-1242-2688.jpg';
import '../assets/splash/apple-splash-2688-1242.jpg';
import '../assets/splash/apple-splash-828-1792.jpg';
import '../assets/splash/apple-splash-1792-828.jpg';
import '../assets/splash/apple-splash-1242-2208.jpg';
import '../assets/splash/apple-splash-2208-1242.jpg';
import '../assets/splash/apple-splash-750-1334.jpg';
import '../assets/splash/apple-splash-1334-750.jpg';
import '../assets/splash/apple-splash-640-1136.jpg';
import '../assets/splash/apple-splash-1136-640.jpg';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('service-worker.js', { scope: '/' });
    navigator.serviceWorker.ready
      .then(registration => {
        console.log('SW registered: ', registration);
      })
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}

const { persistor, store } = configureStore();

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
