import React from 'react';
import { Fragment } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { API_URL } from '../../constants/Config';

const HeaderPage = ({ isBack, backUrl, title, isCommode, isHome, isLogin, isProfil, proche }) => {
  const history = useHistory();

  return (
    <Fragment>
      {proche ? (
        <Fragment>
          <header className="header header-proche">
            <div className="header-container">
              <div className="btn" onClick={() => history.push('/')}>
                <i className="fal fa-home-lg" style={{ color: 'white' }} />
              </div>

              <div className="header-center">
                {proche.profil ? (
                  <Fragment>
                    <div className="proche-avatar">
                      <img
                        src={`${API_URL}/uploads/${proche && proche.profil && proche.user_id}/${
                          proche.profil
                        }`}
                      />
                    </div>
                    <span>{proche.prenom}</span>
                  </Fragment>
                ) : (
                  <div className="header-title">{proche.prenom}</div>
                )}
              </div>
            </div>
          </header>
          {!isCommode && (
            <header className="header header-normal">
              <div className="header-container">
                <span
                  className="btn"
                  onClick={() => (isBack ? history.goBack() : history.push('/'))}
                >
                  <i style={{ color: 'white' }} className="fal fa-long-arrow-left" />
                </span>
                <div className="header-center">
                  {!isHome && !isLogin ? (
                    <span className="header-title">{title}</span>
                  ) : (
                    <img
                      className="logo"
                      src={require(`../../../assets/images/logo-immortaliz.svg`)}
                    />
                  )}
                </div>
                {isProfil && (
                  <NavLink className="btn" to="/mon-compte">
                    <i className="fal fa-user" style={{ color: 'white' }} />
                  </NavLink>
                )}
              </div>
            </header>
          )}
        </Fragment>
      ) : (
        <header className="header header-normal">
          <div className="header-container">
            {isCommode && (
              <NavLink className="btn" to="/">
                <i className="fal fa-home-lg" style={{ color: 'white' }} />
              </NavLink>
            )}
            {!isHome && !isCommode && !isLogin && (
              <span
                className="btn"
                onClick={() =>
                  isBack ? (backUrl ? history.push(backUrl) : history.goBack()) : history.push('/')
                }
              >
                <i style={{ color: 'white' }} className="fal fa-long-arrow-left" />
              </span>
            )}

            <div className="header-center">
              {!isHome && !isLogin ? (
                <span className="header-title">{title}</span>
              ) : (
                <NavLink to="/auth">
                  <img
                    className="logo"
                    src={require(`../../../assets/images/logo-immortaliz.svg`)}
                  />
                </NavLink>
              )}
            </div>
            {isHome && (
              <a
                className="btn btn-text"
                href="https://immortaliz.fr"
                target="_blank"
                rel="noopener"
              >
                <span>Comment ça marche ?</span>
              </a>
            )}
            {isProfil && (
              <NavLink className="btn" to="/mon-compte">
                <i className="fal fa-user" style={{ color: 'white' }} />
              </NavLink>
            )}
          </div>
        </header>
      )}
    </Fragment>
  );
};

export default HeaderPage;
