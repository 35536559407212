import { CALL_API, Schemas } from '../Schemas';

import { values } from 'lodash';

const LOAD_USER_REQUEST = 'immowa/auth/LOAD_USER_REQUEST';
const LOAD_USER_SUCCESS = 'immowa/auth/LOAD_USER_SUCCESS';
const LOAD_USER_FAIL = 'immowa/auth/LOAD_USER_FAIL';

const CAN_ADD_CONTENT_REQUEST = 'immowa/auth/CAN_ADD_CONTENT_REQUEST';
const CAN_ADD_CONTENT_SUCCESS = 'immowa/auth/CAN_ADD_CONTENT_SUCCESS';
const CAN_ADD_CONTENT_FAIL = 'immowa/auth/CAN_ADD_CONTENT_FAIL';

const USE_SPACE_DISK_USER_REQUEST = 'immowa/auth/USE_SPACE_DISK_USER_REQUEST';
const USE_SPACE_DISK_USER_SUCCESS = 'immowa/auth/USE_SPACE_DISK_USER_SUCCESS';
const USE_SPACE_DISK_USER_FAIL = 'immowa/auth/USE_SPACE_DISK_USER_FAIL';

const CHECK_IF_USER_WIZBII_EXIST_REQUEST = 'immowa/auth/CHECK_IF_USER_WIZBII_EXIST_REQUEST';
const CHECK_IF_USER_WIZBII_EXIST_SUCCESS = 'immowa/auth/CHECK_IF_USER_WIZBII_EXIST_SUCCESS';
const CHECK_IF_USER_WIZBII_EXIST_FAIL = 'immowa/auth/CHECK_IF_USER_WIZBII_EXIST_FAIL';

const LOGIN_REQUEST = 'immowa/auth/LOGIN_REQUEST';
const LOGIN_SUCCESS = 'immowa/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'immowa/auth/LOGIN_FAIL';

const EMAIL_EXIST_REQUEST = 'immowa/auth/EMAIL_EXIST_REQUEST';
const EMAIL_EXIST_SUCCESS = 'immowa/auth/EMAIL_EXIST_SUCCESS';
const EMAIL_EXIST_FAIL = 'immowa/auth/EMAIL_EXIST_FAIL';

const REGISTER_REQUEST = 'immowa/auth/REGISTER_REQUEST';
const REGISTER_SUCCESS = 'immowa/auth/REGISTER_SUCCESS';
const REGISTER_FAIL = 'immowa/auth/REGISTER_FAIL';

const LOGOUT_REQUEST = 'immowa/auth/LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'immowa/auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'immowa/auth/LOGOUT_FAIL';

const UPDATE_USER_REQUEST = 'immowa/auth/UPDATE_USER_REQUEST';
const UPDATE_USER_SUCCESS = 'immowa/auth/UPDATE_USER_SUCCESS';
const UPDATE_USER_FAILURE = 'immowa/auth/UPDATE_USER_FAILURE';

const CREATE_PASSWORD_REQUEST = 'immowa/auth/CREATE_PASSWORD_REQUEST';
const CREATE_PASSWORD_SUCCESS = 'immowa/auth/CREATE_PASSWORD_SUCCESS';
const CREATE_PASSWORD_FAILURE = 'immowa/auth/CREATE_PASSWORD_FAILURE';

const RECOVER_PASSWORD_REQUEST = 'immowa/auth/RECOVER_PASSWORD_REQUEST';
const RECOVER_PASSWORD_SUCCESS = 'immowa/auth/RECOVER_PASSWORD_SUCCESS';
const RECOVER_PASSWORD_FAILURE = 'immowa/auth/RECOVER_PASSWORD_FAILURE';

const initialState = {
  loaded: false,
  isConnected: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case LOAD_USER_SUCCESS:
      const dataResponse = values(action.response.entities.user)[0];
      return {
        ...state,
        loading: false,
        loaded: true,
        user: {
          ...dataResponse
        }
      };
    case LOAD_USER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isConnected: true,
        user: values(action.response.entities.user)[0]
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        user: null,
        loginError: action.error
      };
    case CHECK_IF_USER_WIZBII_EXIST_REQUEST:
      return {
        ...state,
        loggingIn: true
      };
    case CHECK_IF_USER_WIZBII_EXIST_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isConnected: true,
        user: values(action.response.entities.user)[0]
      };
    case CHECK_IF_USER_WIZBII_EXIST_FAIL:
      return {
        ...state,
        loggingIn: false,
        user: null,
        loginError: action.error
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        user: null
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isConnected: true,
        user: values(action.response.entities.user)[0]
      };

    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function loadUser(userId) {
  return {
    userId,
    [CALL_API]: {
      types: [LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOAD_USER_FAIL],
      method: 'GET',
      endpoint: `/users/me/${userId}`,
      schema: Schemas.USER
    }
  };
}

export function checkIfUserWizbiiExist(response) {
  return {
    [CALL_API]: {
      types: [
        CHECK_IF_USER_WIZBII_EXIST_REQUEST,
        CHECK_IF_USER_WIZBII_EXIST_SUCCESS,
        CHECK_IF_USER_WIZBII_EXIST_FAIL
      ],
      method: 'POST',
      body: response,
      endpoint: `/users/wizbii`,
      schema: Schemas.USER
    }
  };
}

export function canAddContent(userId) {
  return {
    userId,
    [CALL_API]: {
      types: [CAN_ADD_CONTENT_REQUEST, CAN_ADD_CONTENT_SUCCESS, CAN_ADD_CONTENT_FAIL],
      method: 'GET',
      endpoint: `/users/files/size/${userId}`
    }
  };
}
export function checkUseSpaceDisk(userId) {
  return {
    userId,
    [CALL_API]: {
      types: [USE_SPACE_DISK_USER_REQUEST, USE_SPACE_DISK_USER_SUCCESS, USE_SPACE_DISK_USER_FAIL],
      method: 'GET',
      endpoint: `/users/files/use-space/${userId}`
    }
  };
}

export function uploadFiles(files) {
  return {
    [CALL_API]: {
      types: [UPLOADS_REQUEST, UPLOADS_SUCCESS, UPLOADS_FAILURE],
      method: 'POST',
      endpoint: '/uploads',
      body: files,
      upload: true
    }
  };
}

export function login(email, password) {
  return {
    [CALL_API]: {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAIL],
      method: 'POST',
      endpoint: '/auth/login',
      schema: Schemas.USER,
      body: { email, password }
    }
  };
}

export function emailExist(values) {
  return {
    [CALL_API]: {
      types: [EMAIL_EXIST_REQUEST, EMAIL_EXIST_SUCCESS, EMAIL_EXIST_FAIL],
      method: 'POST',
      endpoint: '/auth/email-exist',
      body: { values }
    }
  };
}

export function register(data) {
  return {
    [CALL_API]: {
      types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAIL],
      method: 'POST',
      endpoint: '/auth/create',
      body: data
    }
  };
}

export function createPassword(data) {
  return {
    [CALL_API]: {
      types: [CREATE_PASSWORD_REQUEST, CREATE_PASSWORD_SUCCESS, CREATE_PASSWORD_FAILURE],
      method: 'PUT',
      endpoint: '/auth/reset-password',
      body: data
    }
  };
}

export function logout() {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function recoverPassword(email) {
  return {
    [CALL_API]: {
      types: [RECOVER_PASSWORD_REQUEST, RECOVER_PASSWORD_SUCCESS, RECOVER_PASSWORD_FAILURE],
      method: 'PUT',
      endpoint: '/auth/password',
      body: {
        email
      }
    }
  };
}

export function updateUser(user, data) {
  return {
    user,
    [CALL_API]: {
      types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
      method: 'PUT',
      schema: Schemas.USER,
      body: data,
      endpoint: `/users/${user.user_id}`,
      successMessage: 'Votre compte a bien été modifier'
    }
  };
}
