import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { register, emailExist } from '../../store/modules/auth';

import BaseLayout from '../shared/BaseLayout';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

import MomentUtils from '@date-io/moment';

import InformationCreationClient from './InformationCreationClient';
import SelectPack from './SelectPack';
import CheckoutForm from '../stripe/CheckoutForm';

const RegisterPage = ({ register, isInscription, emailExist }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPack, setSelectedPack] = useState(2);

  const history = useHistory();

  const [error, setError] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(false);

  const [user, setUser] = useState({});

  const handleSubmit = values => {
    // il faut créer le compte que si on est dans l'inscription et que c'est abonnement freemium
    // si c'est premium, on doit attendre que le paiement soit valider
    setError(null);
    // il faut vérifier si le mail n'existe pas dans la base :
    return emailExist(values).then(res => {
      if (res.response) {
        // on est dan sle cas ou le mail est déjà dans la base de donnée
        // il faut retourner le formulaire en erreur
        // todo jules
        setError('Email existe déjà');
      } else {
        // on est dans le cas ou le mail n'existe pas dan sla bdd donc on peut créer et ajouter l'utilisateur

        if (isInscription && selectedPack === 1) {
          return register({
            ...values,
            isPremium: false // car selectedPack === 1 donc on est en abonnement freemium,
          })
            .then(() => {
              setModalSuccess(true);
            })
            .catch(err => setError(err));
        }
        if (isInscription && selectedPack === 2) {
          // il faut une méthode pour garder en memoire l'utilisateur pour l'inscrire que quand il a validé son paiement
          return Promise.resolve()
            .then(() =>
              setUser({
                ...values,
                isPremium: true
              })
            )
            .then(() => nextStep());
        }
      }
    });
  };

  const confirmOk = () => {
    setModalSuccess(false);
    return history.push('connexion');
  };

  // Fonction pour passer à l'étape suivante
  const nextStep = () => {
    setError(null);
    setCurrentStep(currentStep + 1);
  };

  // Fonction pour revenir à l'étape précédente
  const prevStep = () => {
    setError(null);

    setCurrentStep(currentStep - 1);
  };

  return (
    <BaseLayout isLogin classScreen="auth login">
      <div className="container">
        <div className="logo-block">
          <img src={require(`../../../assets/images/logo-immortaliz-vertical.svg`)} />
          <div className="title">
            Etape {currentStep}&nbsp;:&nbsp;{currentStep === 1 && 'Choix de la formule'}
            {currentStep === 2 && 'Inscription'}
            {currentStep === 3 && 'Paiement'}
          </div>
        </div>
        <div></div>

        <Modal {...modalDefaultClass} id="modal-confirm" isOpen={modalSuccess}>
          <div className="modal-title">
            Confirmation
            <div onClick={() => confirmOk()} className="btn-close">
              <i className="far fa-times" />
            </div>
          </div>
          <div className="modal-body">
            Votre compte Immortaliz a été créé avec succès, vous allez recevoir un e-mail vous
            permettant de définir votre mot de passe pour vous permettre de vous connecter.
            <div className="btn-group center">
              <button onClick={() => confirmOk()} className="btn btn-primary">
                <span>OK</span>
              </button>
            </div>
          </div>
        </Modal>
        <Fragment>
          {currentStep === 1 && (
            <SelectPack
              nextStep={nextStep}
              prevStep={prevStep}
              isInscription={isInscription}
              selectedPack={selectedPack}
              setSelectedPack={p => setSelectedPack(p)}
            />
          )}
          {currentStep === 2 && (
            <InformationCreationClient
              nextStep={nextStep}
              prevStep={prevStep}
              isInscription={isInscription}
              selectedPack={selectedPack}
              handleSubmit={handleSubmit}
              errorProps={error}
            />
          )}
          {currentStep === 3 && (
            <CheckoutForm
              nextStep={nextStep}
              prevStep={prevStep}
              isInscription={isInscription}
              user={user}
              selectedPack={selectedPack}
            />
          )}
        </Fragment>
      </div>
    </BaseLayout>
  );
};

RegisterPage.propTypes = {
  register: PropTypes.func.isRequired
};

export default connect(null, { register, emailExist })(RegisterPage);
