import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { validateEmail } from '../../utils/StringValidator';
import { ACCES_TIROIRS } from '../../constants/Properties';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createProche, getUserByEmail } from '../../store/modules/proches';

import { map, find, values, includes, filter, toLower } from 'lodash';

import Modal from 'react-modal';

import { Form, Field } from 'react-final-form';
import Select from 'react-select';

const ModalAddProche = ({
  createProche,
  commodes,
  getUserByEmail,
  user,
  proches,
  closeOtherModal
}) => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [freemium, setFreemium] = useState(false);
  const [premium, setPremium] = useState(false);
  const [etape, setEtape] = useState(1);
  const [accountExist, setAccountExist] = useState(null);
  const [error, setError] = useState(null);

  const commodeId = find(commodes, c => c.user_id === user.user_id).commode_id;

  const [openModalPremium, setOpenModalPremium] = useState(false);
  const [openModalObendy, setOpenModalObendy] = useState(false);

  const [proche, setProche] = useState({
    parrain: { user_id: user.user_id, email: user.email, prenom: user.prenom, nom: user.nom }
  });

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';

  const optionsAcces = map(ACCES_TIROIRS, (item, key) => {
    return { value: key, label: item };
  });

  const validateProche = values => {
    const data = {
      ...proche,
      commodeId,
      droits: [
        { 'ma-vie': values.ma_vie },
        { 'a-partager': values.a_partager },
        { 'mes-directives': values.mes_directives },
        { administratif: values.administratif },
        {
          'mon-depart': values.mon_depart
        }
      ]
    };
    setProche(data);

    // on passe commodeId pour pouvoir recharger les tiroirs après création du proche
    return createProche(data, commodeId)
      .then(res => {
        setIsOpen(false);

        setProche({
          user: null,
          parrain: { user_id: user.user_id, email: user.email, prenom: user.prenom, nom: user.nom }
        });
        setEtape(1);
        if (closeOtherModal) {
          closeOtherModal();
        }
      })
      .catch(err => {
        setError(err);
      });
  };

  const checkingUser = data => {
    () => setError(null);
    const procheExist = find(proches, p => p.email === data.email);

    if (data.email === user.email) {
      setError('Vous ne pouvez pas vous ajouter comme proche !');
    } else if (procheExist) {
      setError('Vous ne pouvez pas ajouter 2 fois le même proche !');
    } else {
      return getUserByEmail(toLower(data.email))
        .then(res => {
          if (res.response.user) {
            const user = res.response.user;
            setAccountExist(user);
            setProche({ ...proche, user, accountExist: true });
          } else {
            const user = { email: data.email };
            setAccountExist(false);
            setProche({ ...proche, user, accountExist: false });
          }
          setEtape(etape + 1);
        })
        .catch(err => {
          setError(err);
        });
    }
  };

  const submitInfosUser = data => {
    () => setError(null);
    if (proche.accountExist) {
      setProche({ ...proche, user: accountExist });
    } else {
      setProche({ ...proche, user: data });
    }
    setEtape(etape + 1);
  };
  return (
    <Fragment>
      <div className="btn-group sticky center">
        <button
          className="btn btn-primary"
          onClick={() => {
            setError(null);
            if (user && user.obendy && values(proches).length >= 6) {
              return setOpenModalObendy(true);
            }
            if (!user.isPremium && values(proches).length >= 1) {
              setFreemium(true);
              return setOpenModalPremium(true);
            }
            if (user.isPremium && values(proches).length >= 6) {
              setPremium(true);
              return setOpenModalPremium(true);
            }
            setIsOpen(!isOpen);
          }}
        >
          J'ajoute un proche
        </button>
      </div>

      <Modal
        className="modal-content"
        overlayClassName="modal-overlay modal-custom"
        id="modal-premium"
        isOpen={openModalPremium}
      >
        <div className="modal-title">
          <div
            onClick={() => {
              setPremium(false);
              setFreemium(false);
              setOpenModalPremium(false);
              // avant, on redirigeait sur l'ajout d'un proche, maintenant on redirige sur la modificatino de l'abonnemnet
              // du coup, on n'a plus a ouvrir la modal si on ferme la modal avec les infos pour aller sur mode payant
              // setIsOpen(true);
            }}
            className="btn-close"
          >
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <i className="fal fa-triangle-exclamation modal-custom-icon" />
          <p>
            Vous ne pouvez pas ajouter un nouveau proche, vous avez atteint la limite de proches
            désignés.
          </p>
          {freemium ? (
            <Fragment>
              <p>Passez à la formule Premium et bénéficiez de 6&nbsp;proches désignés !</p>
              <div className="btn-group center">
                <div
                  className="btn btn-secondary"
                  onClick={() => {
                    history.push('/abonnements/update');
                  }}
                >
                  Je découvre la formule Premium !
                </div>
              </div>
            </Fragment>
          ) : null}
        </div>
      </Modal>
      <Modal
        className="modal-content"
        overlayClassName="modal-overlay modal-custom"
        id="modal-premium"
        isOpen={openModalObendy}
      >
        <div className="modal-title">
          <div
            onClick={() => {
              setOpenModalObendy(false);
              setIsOpen(true);
            }}
            className="btn-close"
          >
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          <i className="fal fa-triangle-exclamation modal-custom-icon" />
          <p>
            Vous ne pouvez pas ajouter de proche. Vous avez atteint la limite de 6 proches désignés.
          </p>
        </div>
      </Modal>

      <Modal
        className="modal-content full-modal"
        overlayClassName="modal-overlay modal-add-content"
        id="modal-contenu"
        isOpen={isOpen}
      >
        <div className="modal-title">
          J'ajoute un proche
          <div
            onClick={() => {
              setIsOpen(false);
              setAccountExist(null);
              setEtape(1);
              setProche({
                user: null,
                parrain: {
                  user_id: user.user_id,
                  email: user.email,
                  prenom: user.prenom,
                  nom: user.nom
                }
              });
              if (closeOtherModal) {
                closeOtherModal();
              }
            }}
            className="btn-close"
          >
            <i className="far fa-times" />
          </div>
        </div>
        <div className="modal-body">
          {etape === 1 && (
            <Form
              onSubmit={checkingUser}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field validate={requiredEmail} name="email" component="input" className="field">
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="email" autoCapitalize="none" />
                        <label>Email *</label>
                      </div>
                    )}
                  </Field>

                  {error && <div className="error-block">{error}</div>}
                  <div className="btn-group center">
                    <button type="submit" className="btn btn-primary">
                      <span>Suivant</span>
                    </button>
                  </div>
                </form>
              )}
            />
          )}
          {etape === 2 && (
            <Form
              onSubmit={submitInfosUser}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    validate={requiredEmail}
                    initialValue={
                      (accountExist && accountExist.email) ||
                      (proche && proche.user && proche.user.email)
                    }
                    name="email"
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" disabled={accountExist} />
                        <label>Email *</label>
                      </div>
                    )}
                  </Field>

                  <Field
                    name="prenom"
                    initialValue={(accountExist && accountExist.prenom) || ''}
                    validate={required}
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" disabled={accountExist} />
                        <label>Prénom *</label>
                      </div>
                    )}
                  </Field>

                  <Field
                    validate={required}
                    initialValue={(accountExist && accountExist.nom) || ''}
                    name="nom"
                    component="input"
                    className="field"
                  >
                    {({ input, meta }) => (
                      <div className="field">
                        {meta.error && meta.touched && (
                          <div className="field-error">{meta.error}</div>
                        )}
                        <input {...input} type="text" disabled={accountExist} />
                        <label>Nom *</label>
                      </div>
                    )}
                  </Field>
                  {accountExist &&
                    setError(`Un compte existe déja avec cet e-mail, vérifiez bien les informations
                      pré-remplies`)
                  // <p>
                  //   Un compte existe déja avec cet e-mail, vérifiez bien les informations
                  //   pré-remplies
                  // </p>
                  }
                  {error && <div className="error-block">{error}</div>}
                  <div className="btn-group center">
                    <button
                      type="submit"
                      onClick={() => {
                        setError(null);
                        setProche({
                          email: null,
                          parrain: {
                            user_id: user.user_id,
                            email: user.email,
                            prenom: user.prenom,
                            nom: user.nom
                          },
                          accountExist: false,
                          user: null
                        });
                        setEtape(etape - 1);
                      }}
                      className="btn btn-primary"
                    >
                      <span>Annuler</span>
                    </button>
                    <button type="submit" className="btn btn-primary">
                      <span>Suivant</span>
                    </button>
                  </div>
                </form>
              )}
            />
          )}
          {etape === 3 && (
            <Form
              onSubmit={validateProche}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <h3>Droits d'accès aux tiroirs</h3>
                  <Field
                    validate={required}
                    name="ma_vie"
                    component="select"
                    initialValue={optionsAcces[1].value}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          <Select
                            options={optionsAcces}
                            onChange={option => {
                              input.onChange(option.value);
                            }}
                            value={find(optionsAcces, o => o.value === input.value)}
                          />

                          <label>L'album de ma vie</label>
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    validate={required}
                    name="a_partager"
                    component="select"
                    initialValue={optionsAcces[1].value}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          <Select
                            options={optionsAcces}
                            onChange={option => {
                              input.onChange(option.value);
                            }}
                            value={find(optionsAcces, o => o.value === input.value)}
                          />

                          <label>Ce que je veux partager</label>
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    validate={required}
                    name="mes_directives"
                    component="select"
                    initialValue={optionsAcces[1].value}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          <Select
                            options={optionsAcces}
                            onChange={option => {
                              input.onChange(option.value);
                            }}
                            value={find(optionsAcces, o => o.value === input.value)}
                          />

                          <label>Mes souhaits en cas d’urgence</label>
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    validate={required}
                    name="administratif"
                    component="select"
                    initialValue={optionsAcces[1].value}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          <Select
                            options={optionsAcces}
                            onChange={option => {
                              input.onChange(option.value);
                            }}
                            value={find(optionsAcces, o => o.value === input.value)}
                          />

                          <label>Mes documents administratifs</label>
                        </div>
                      );
                    }}
                  </Field>
                  <Field
                    validate={required}
                    name="mon_depart"
                    component="select"
                    initialValue={optionsAcces[1].value}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          <Select
                            options={optionsAcces}
                            onChange={option => {
                              input.onChange(option.value);
                            }}
                            value={find(optionsAcces, o => o.value === input.value)}
                          />

                          <label>Mon départ</label>
                        </div>
                      );
                    }}
                  </Field>
                  {/* <Field
                    validate={required}
                    name="postez_moi"
                    component="select"
                    initialValue={optionsAccesLimite[1].value}
                  >
                    {({ input, meta }) => {
                      return (
                        <div className="field">
                          {meta.error && meta.touched && (
                            <div className="field-error">{meta.error}</div>
                          )}

                          <Select
                            options={optionsAccesLimite}
                            onChange={option => {
                              input.onChange(option.value);
                            }}
                            value={find(optionsAcces, o => o.value === input.value)}
                          />

                          <label>Postez-moi un message</label>
                        </div>
                      );
                    }}
                  </Field> */}
                  {error && <div className="error-block">{error}</div>}
                  <div className="btn-group center">
                    <button
                      type="submit"
                      onClick={() => setEtape(etape - 1)}
                      className="btn btn-primary"
                    >
                      <span>Précédent</span>
                    </button>
                    <button type="submit" className="btn btn-primary">
                      <span>Ajouter</span>
                    </button>
                  </div>
                </form>
              )}
            />
          )}
        </div>
      </Modal>
    </Fragment>
  );
};

ModalAddProche.propTypes = {
  createProche: PropTypes.func.isRequired,
  getUserByEmail: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    proches: filter(state.data.entities.proches, p =>
      includes(p.parrain_id, state.auth.user.user_id)
    ),
    commodes: state.data.entities.commodes && values(state.data.entities.commodes)
  };
};

export default connect(mapStateToProps, { createProche, getUserByEmail })(ModalAddProche);
