import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { version } from '../../package.json';
import { connect } from 'react-redux';

import { logout } from '../store/modules/auth';
import { purge } from '../store/modules/settings';

import BaseLayout from './shared/BaseLayout';

const MonComptePage = ({ logout, purge, user }) => {
  const deconnexion = () => {
    return Promise.resolve()
      .then(() => logout())
      .then(() => purge());
  };

  return (
    <BaseLayout headerPageTitle="Mon compte">
      <div className="container alignCenter">
        <div className="content">
          <NavLink to="/mes-infos-personnelles" className="btn btn-account">
            <span className="btn-account-info">
              <i className="far fa-user" />
              <span>Mes infos personnelles</span>
            </span>
            <i className="far fa-chevron-right" />
          </NavLink>

          <NavLink to="/mes-proches" className="btn btn-account">
            <span className="btn-account-info">
              <i className="far fa-users" />
              <span>Mes proches</span>
            </span>
            <i className="far fa-chevron-right" />
          </NavLink>
          <NavLink to="/abonnements" className="btn btn-account">
            <span className="btn-account-info">
              <i className="far fa-credit-card-front" />
              <span>Abonnements</span>
            </span>
            <i className="far fa-chevron-right" />
          </NavLink>

          <span className="version">
            <i className="far fa-laptop-code" />
            Version {version}
          </span>
          <div className="mt-20 btn-group center flexColumn">
            <a
              className="btn btn-link btn-small"
              target="_blank"
              href="https://www.immortaliz.fr/mentions-legales/"
            >
              <span>Mentions légales</span>
            </a>

            <a
              className="btn btn-link btn-small"
              target="_blank"
              href="https://www.immortaliz.fr/cgu/"
            >
              <span>Condition Générales d'Utilisation</span>
            </a>
          </div>
        </div>
        {user && !user.obendy && (
          <div className="btn-group sticky center">
            <NavLink className="btn btn-primary" onClick={() => deconnexion()} to="/connexion">
              Déconnexion
            </NavLink>
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

MonComptePage.propTypes = {
  logout: PropTypes.func.isRequired,
  purge: PropTypes.func.isRequired
};

export default connect(
  state => ({
    user: state.auth.user
  }),
  { logout, purge }
)(MonComptePage);
