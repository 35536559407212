import { flow, set } from 'lodash/fp';
import { findIndex, union, remove } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

import initialState from '../initialState';
const GET_ALL_CATEGORIES = 'immowa/GET_ALL_CATEGORIES_REQUEST';
const PURGE = 'immowa/PURGE';
const GET_ALL_CATEGORIES_SUCCESS = 'immowa/GET_ALL_CATEGORIES_SUCCESS';
const GET_ALL_CATEGORIES_FAILURE = 'immowa/GET_ALL_CATEGORIES_FAILURE';

const CREATE_CATEGORIE_REQUEST = 'immowa/CREATE_CATEGORIE_REQUEST';
const CREATE_CATEGORIE_SUCCESS = 'immowa/CREATE_CATEGORIE_SUCCESS';
const CREATE_CATEGORIE_FAILURE = 'immowa/CREATE_CATEGORIE_FAILURE';

const DELETE_CATEGORIE_REQUEST = 'immowa/DELETE_CATEGORIE_REQUEST';
const DELETE_CATEGORIE_SUCCESS = 'immowa/DELETE_CATEGORIE_SUCCESS';
const DELETE_CATEGORIE_FAILURE = 'immowa/DELETE_CATEGORIE_FAILURE';

const UPDATE_CATEGORIE_REQUEST = 'immowa/UPDATE_CATEGORIE_REQUEST';
const UPDATE_CATEGORIE_SUCCESS = 'immowa/UPDATE_CATEGORIE_SUCCESS';
const UPDATE_CATEGORIE_FAILURE = 'immowa/UPDATE_CATEGORIE_FAILURE';

export const categoriesActionsHandlers = {
  [PURGE]: () => initialState,
  [GET_ALL_CATEGORIES]: state =>
    flow(set('loaded.categories', false), set('loading.categories', true))(state),
  [GET_ALL_CATEGORIES_SUCCESS]: (state, action) => {
    return flow(
      set('entities.categories', action.response.entities.categories || {}),
      set('loaded.categories', true),
      set('loading.categories', false)
    )(state);
  },
  [GET_ALL_CATEGORIES_FAILURE]: state =>
    flow(set('loaded.categories', false), set('loading.categories', false))(state),
  [CREATE_CATEGORIE_REQUEST]: state =>
    flow(set('loaded.categories', false), set('loading.categories', true))(state),
  [CREATE_CATEGORIE_SUCCESS]: (state, action) => {
    return flow(
      set(
        `entities.categories.${action.slug}.categories`,
        union(
          state.entities.categories[action.slug].categories,
          action.response.entities.categories[action.slug].categories
        )
      ),
      set('loaded.categories', true),
      set('loading.categories', false)
    )(state);
  },
  [CREATE_CATEGORIE_FAILURE]: state =>
    flow(set('loaded.categories', false), set('loading.categories', false))(state),
  [UPDATE_CATEGORIE_REQUEST]: state =>
    flow(set('loaded.categories', false), set('loading.categories', true))(state),
  [UPDATE_CATEGORIE_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.categories.${action.slug}.categories[${action.arrayId}]`, action.response),
      set('loaded.categories', true),
      set('loading.categories', false)
    )(state);
  },
  [UPDATE_CATEGORIE_FAILURE]: state =>
    flow(set('loaded.categories', false), set('loading.categories', false))(state),
  [DELETE_CATEGORIE_REQUEST]: state =>
    flow(set('loaded.categories', false), set('loading.categories', true))(state),
  [DELETE_CATEGORIE_SUCCESS]: (state, action) => {
    return flow(
      set(
        `entities.categories.${action.slug}.categories`,
        remove(
          state.entities.categories[action.slug].categories,
          (v, index) => index !== action.arrayId
        )
      ),
      set('loaded.categories', true),
      set('loading.categories', false)
    )(state);
  },
  [DELETE_CATEGORIE_FAILURE]: state =>
    flow(set('loaded.categories', false), set('loading.categories', false))(state)
};

export function purge() {
  return {
    type: PURGE
  };
}

export function getCategories(commodeId) {
  return {
    [CALL_API]: {
      types: [GET_ALL_CATEGORIES, GET_ALL_CATEGORIES_SUCCESS, GET_ALL_CATEGORIES_FAILURE],
      method: 'GET',
      endpoint: `/categories/${commodeId}`,
      schema: Schemas.CATEGORIE_ARRAY,
      successMessage: 'Categories chargés avec succès'
    }
  };
}

export function createCategorie(data) {
  return {
    slug: data.slug,
    [CALL_API]: {
      types: [CREATE_CATEGORIE_REQUEST, CREATE_CATEGORIE_SUCCESS, CREATE_CATEGORIE_FAILURE],
      method: 'POST',
      endpoint: '/categories',
      schema: Schemas.CATEGORIE_ARRAY,
      body: data
    }
  };
}

export function updateCategorie(categories, data) {
  // on retourne l'index du tableau de la valeur qqu'on veut modifier
  return {
    arrayId: findIndex(categories.categories, cat => cat.value === data.value),
    slug: data.slug,
    [CALL_API]: {
      types: [UPDATE_CATEGORIE_REQUEST, UPDATE_CATEGORIE_SUCCESS, UPDATE_CATEGORIE_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/categories/${data.categorie_id}`,
      successMessage: 'La categorie a bien été modifié'
    }
  };
}
export function removeCategorie(categories, data) {
  // on retourne l'index du tableau de la valeur qqu'on veut modifier
  return {
    arrayId: findIndex(categories.categories, cat => cat.value === data.value),
    slug: data.slug,
    [CALL_API]: {
      types: [DELETE_CATEGORIE_REQUEST, DELETE_CATEGORIE_SUCCESS, DELETE_CATEGORIE_FAILURE],
      method: 'DELETE',
      endpoint: `/categories/${data.categorie_id}`,
      successMessage: 'La categorie a bien été supprimé'
    }
  };
}
