import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';
import { schema } from 'normalizr';

import { CALL_API, Schemas } from '../Schemas';

const CREATE_CONTENU_REQUEST = 'immowa/CREATE_CONTENU_REQUEST';
const CREATE_CONTENU_SUCCESS = 'immowa/CREATE_CONTENU_SUCCESS';
const CREATE_CONTENU_FAILURE = 'immowa/CREATE_CONTENU_FAILURE';

const GET_CONTENUS_REQUEST = 'immowa/GET_CONTENUS_REQUEST';
const GET_CONTENUS_SUCCESS = 'immowa/GET_CONTENUS_SUCCESS';
const GET_CONTENUS_FAILURE = 'immowa/GET_CONTENUS_FAILURE';

const DELETE_CONTENU_REQUEST = 'immowa/DELETE_CONTENU_REQUEST';
const DELETE_CONTENU_SUCCESS = 'immowa/DELETE_CONTENU_SUCCESS';
const DELETE_CONTENU_FAILURE = 'immowa/DELETE_CONTENU_FAILURE';

const UPDATE_CONTENU_REQUEST = 'immowa/UPDATE_CONTENU_REQUEST';
const UPDATE_CONTENU_SUCCESS = 'immowa/UPDATE_CONTENU_SUCCESS';
const UPDATE_CONTENU_FAILURE = 'immowa/UPDATE_CONTENU_FAILURE';

export const contenusActionsHandlers = {
  [GET_CONTENUS_SUCCESS]: (state, action) =>
    flow(
      set('entities.contenus', action.response.entities.contenus || {}),
      set('loaded.contenus', true),
      set('loading.contenus', false)
    )(state),

  [CREATE_CONTENU_SUCCESS]: (state, action) => {
    return flow(
      set('entities.contenus', {
        ...state.entities.contenus,
        ...action.response.entities.contenus
      }),
      set('loaded.contenus', true),
      set('loading.contenus', false)
    )(state);
  },
  [DELETE_CONTENU_SUCCESS]: (state, action) =>
    flow(set('entities.contenus', omit(state.entities.contenus, action.contenu.contenu_id)))(state),

  [UPDATE_CONTENU_SUCCESS]: (state, action) => {
    return flow(
      set(`entities.contenus.${action.contenu.contenu_id}`, {
        ...action.contenu,
        ...action.response
      }),
      set('loaded.contenus', true),
      set('loading.contenus', false)
    )(state);
  }
};

// CREATE

export function createContenu(data) {
  return {
    [CALL_API]: {
      types: [CREATE_CONTENU_REQUEST, CREATE_CONTENU_SUCCESS, CREATE_CONTENU_FAILURE],
      method: 'POST',
      endpoint: '/contenus',
      schema: Schemas.CONTENU,
      body: data
    }
  };
}

// READ

export function getContenus(tiroirId) {
  return {
    [CALL_API]: {
      types: [GET_CONTENUS_REQUEST, GET_CONTENUS_SUCCESS, GET_CONTENUS_FAILURE],
      method: 'GET',
      endpoint: `/contenus/${tiroirId}`,
      schema: Schemas.CONTENU_ARRAY,
      successMessage: 'Contenus chargés avec succès'
    }
  };
}

// UPDATE

export function updateContenu(contenu, data, userId = null) {
  return {
    contenu,
    userId,
    [CALL_API]: {
      types: [UPDATE_CONTENU_REQUEST, UPDATE_CONTENU_SUCCESS, UPDATE_CONTENU_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/contenus/${contenu.contenu_id}/${userId}`,
      successMessage: 'Le contenu a bien été modifié'
    }
  };
}

export function moveContenu(contenu, data, userId = null) {
  return {
    contenu,
    userId,
    [CALL_API]: {
      types: [UPDATE_CONTENU_REQUEST, UPDATE_CONTENU_SUCCESS, UPDATE_CONTENU_FAILURE],
      method: 'PUT',
      body: data,
      endpoint: `/contenus/move/${contenu.contenu_id}/${userId}`,
      successMessage: 'Le contenu a bien été déplacé'
    }
  };
}

// DELETE

export function deleteContenu(contenu) {
  return {
    contenu,
    [CALL_API]: {
      types: [DELETE_CONTENU_REQUEST, DELETE_CONTENU_SUCCESS, DELETE_CONTENU_FAILURE],
      method: 'DELETE',
      body: contenu,
      endpoint: `/contenus/${contenu.contenu_id}`,
      successMessage: 'Le contenu a bien été supprimé'
    }
  };
}
