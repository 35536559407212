import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
// import fetch from 'isomorphic-fetch';

import qs from 'qs';
import { version } from '../../../package.json';

import { values } from 'lodash';
import axios from 'axios';
import { connect } from 'react-redux';
import { checkIfUserWizbiiExist, updateUser } from '../../store/modules/auth';

import BaseLayout from '../shared/BaseLayout';

const WizbiiPage = ({ checkIfUserWizbiiExist, updateUser, user }) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  {
    /* https://stackoverflow.com/questions/35352638/how-to-get-parameter-value-from-query-string
          React Router v4/v5, without hooks, generic
React Router v4 does not parse the query for you any more, but you can only access it via this.props.location.search (or useLocation, see below). For reasons see nbeuchat's answer.
E.g. with qs library imported as qs you could do
qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).__firebase_request_key */
  }

  function callWizbii() {
    // valeur test pour linaia
    // http://localhost:3000/wizbii_redirect?username=neil-linaia&token=gQnFqTaBVj2X6EUNrzPEhk3Pvi25TE3D
    let username = qs.parse(location.search, { ignoreQueryPrefix: true }).username;
    let token = qs.parse(location.search, { ignoreQueryPrefix: true }).token;

    const fullUrl = `https://p.api.wizbii.com/profile/v2/${username}/basic-info?token=${token}`;
    const options = {
      method: 'GET'
      // credentials: 'include',
    };

    var myHeaders = new Headers();
    myHeaders.append('Authorization', 'Bearer yoFibM4X4eSr1u17cFBfor0wTHBIet0i');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    return fetch(fullUrl, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        return result;
      })
      .then(response => {
        let mockResponse = {
          analytics_url: 'https://a.wizbii.com/lib/a.js',
          email: 'support+wizbii@linaia.com',
          first_name: 'Neil',
          last_name: 'Linaia',
          package: 'whitelabel',
          partner_id: 'sandbox-partner',
          pixel_analytics_url: 'https://p.wizbii.com/lib/a.js?partner-id=sandbox-partner',
          username: 'neil-linaia',
          widget_url: 'https://p.api.wizbii.com/w/whitelabel.js?wizt=1698742074&wizs=immortaliz'
        };

        // d'après la doc :
        //       <script>
        //     (function(e,a){var n=document.createElement("script");var c=Date.now();var o=a.indexOf("?")===-1?"?":"&";n.src=a+o+"z="+c;var i=document.getElementsByTagName("script")[0];var m=i.parentElement;m.insertBefore(n,i)})(window,{{pixel_analytics_url}});
        // </script>

        return checkIfUserWizbiiExist(response);
      })
      .then(res => {
        if (
          res &&
          res.response &&
          res.response.entities &&
          res.response.entities.user &&
          values(res.response.entities.user) &&
          values(res.response.entities.user)[0]
        ) {
          history.push('/');
        } else {
          setLoading(false);
        }
      })
      .catch(error => console.log('error', error));
    // d'après la doc :
    // {
    //   "username": "philippe-brochier-13",
    //   "email": "philippe.brochier+13@wizbii.com",
    //   "first_name": "Philippe",
    //   "last_name": "Brochier",
    //   "package": "client-v2",
    //   "zip_code": "38000",
    //   "widget_url": "https:\/\/p.notre-plateforme-fidelisation.com\/w\/default-v2.js",
    //   "pixel_analytics_url":"https:\/\/p.notre-plateforme-fidelisation.com\/lib\/a.js"
    // }

    return Promise.resolve({
      username: 'neil-linaia-14',
      email: ' test@linaia.com',
      first_name: 'neil',
      last_name: 'linaia',
      widget_url: 'https://p.notre-plateforme-fidelisation.com/w/default-v2.js',
      pixel_analytics_url: 'https://p.notre-plateforme-fidelisation.com/lib/a.js'
    })
      .then(response => {
        let mockResponse = {
          username: 'neil-linaia-14',
          email: ' test@linaia.com',
          first_name: 'neil',
          last_name: 'linaia',
          widget_url: 'https://p.notre-plateforme-fidelisation.com/w/default-v2.js',
          pixel_analytics_url: 'https://p.notre-plateforme-fidelisation.com/lib/a.js'
        };
        // d'après la doc :
        //       <script>
        //     (function(e,a){var n=document.createElement("script");var c=Date.now();var o=a.indexOf("?")===-1?"?":"&";n.src=a+o+"z="+c;var i=document.getElementsByTagName("script")[0];var m=i.parentElement;m.insertBefore(n,i)})(window,{{pixel_analytics_url}});
        // </script>

        return checkIfUserWizbiiExist(mockResponse);
      })
      .then(res => {
        if (res && res.response && res.response.user && res.response.user.validation_cgu) {
          history.push('/');
        } else {
          setLoading(false);
        }
      })
      .catch(err => console.error(err));
  }

  // quand on arrive sur cette page, il faut appeler l'api obendy pour avoir les inforamtions de l'utilisateur ainsi que les urls à afficher.
  // je pense qu'on va stocker ca dans notre base de donnée ca sera peut etre plus simple.
  useEffect(() => {
    // il faut appeler obendy :
    // https://p.api.wizbii.com/profile/v2/{username}/basic-info?token={token}
    callWizbii();
  }, []);

  function acceptCGU() {
    return Promise.resolve()
      .then(() => {
        return updateUser(user, { validation_cgu: true });
      })
      .then(res => {
        history.push('/');
      });
  }
  return (
    <BaseLayout isLogin classScreen="auth login">
      <div className="container">
        <div className="logo-block">
          <img src={require(`../../../assets/images/logo-immortaliz-vertical.svg`)} />
          <div className="title">Transmettez à vos proches ce qui vous tient le plus à cœur</div>
        </div>
        <div className="auth-buttons">
          {/* <button className="btn btn-primary" onClick={() => acceptCGU()}>
                Je valide les cgu
              </button> */}

          <span className="version">
            <i className="far fa-laptop-code" />
            Version {version}
          </span>
        </div>
        {/* {loading ? (
          <div>Loading</div>
        ) : (
          <div>
            <div className="login-message">
              Bienvenue dans le service immortaliz
              <br />
              en partenariat avec Obendy.
              <br />
              <br />
              Pour utiliser Immortaliz merci de bien vouloir accepter les&nbsp;
              <a href="https://www.immortaliz.fr/cgu" target="_blank" rel="noopener">
                conditions générales d'utilisation.
              </a>
            </div>
          </div>
        )} */}
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = state => ({
  user: state && state.auth && state.auth.user
});

export default connect(mapStateToProps, { checkIfUserWizbiiExist, updateUser })(WizbiiPage);
