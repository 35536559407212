import { CALL_API, Schemas } from '../Schemas';

import { values } from 'lodash';

const CREATE_CHECKOUT_SESSION_REQUEST = 'immowa/auth/CREATE_CHECKOUT_SESSION_REQUEST';
const CREATE_CHECKOUT_SESSION_SUCCESS = 'immowa/auth/CREATE_CHECKOUT_SESSION_SUCCESS';
const CREATE_CHECKOUT_SESSION_FAIL = 'immowa/auth/CREATE_CHECKOUT_SESSION_FAIL';

const RECUP_CHECKOUT_SESSION_REQUEST = 'immowa/auth/RECUP_CHECKOUT_SESSION_REQUEST';
const RECUP_CHECKOUT_SESSION_SUCCESS = 'immowa/auth/RECUP_CHECKOUT_SESSION_SUCCESS';
const RECUP_CHECKOUT_SESSION_FAIL = 'immowa/auth/RECUP_CHECKOUT_SESSION_FAIL';

const GET_PRICES_REQUEST = 'immowa/auth/GET_PRICES_REQUEST';
const GET_PRICES_SUCCESS = 'immowa/auth/GET_PRICES_SUCCESS';
const GET_PRICES_FAIL = 'immowa/auth/GET_PRICES_FAIL';

const GET_PAYMENT_INTENT_REQUEST = 'immowa/auth/GET_PAYMENT_INTENT_REQUEST';
const GET_PAYMENT_INTENT_SUCCESS = 'immowa/auth/GET_PAYMENT_INTENT_SUCCESS';
const GET_PAYMENT_INTENT_FAIL = 'immowa/auth/GET_PAYMENT_INTENT_FAIL';

const GET_SESSION_STRIPE_REQUEST = 'immowa/auth/GET_SESSION_STRIPE_REQUEST';
const GET_SESSION_STRIPE_SUCCESS = 'immowa/auth/GET_SESSION_STRIPE_SUCCESS';
const GET_SESSION_STRIPE_FAIL = 'immowa/auth/GET_SESSION_STRIPE_FAIL';

const GET_SUBSCRIPTION_STRIPE_REQUEST = 'immowa/auth/GET_SUBSCRIPTION_STRIPE_REQUEST';
const GET_SUBSCRIPTION_STRIPE_SUCCESS = 'immowa/auth/GET_SUBSCRIPTION_STRIPE_SUCCESS';
const GET_SUBSCRIPTION_STRIPE_FAIL = 'immowa/auth/GET_SUBSCRIPTION_STRIPE_FAIL';

const GET_CUSTOMER_STRIPE_REQUEST = 'immowa/auth/GET_CUSTOMER_STRIPE_REQUEST';
const GET_CUSTOMER_STRIPE_SUCCESS = 'immowa/auth/GET_CUSTOMER_STRIPE_SUCCESS';
const GET_CUSTOMER_STRIPE_FAIL = 'immowa/auth/GET_CUSTOMER_STRIPE_FAIL';

const CANCEL_SUBSCRIPTION_REQUEST = 'immowa/auth/CANCEL_SUBSCRIPTION_REQUEST';
const CANCEL_SUBSCRIPTION_SUCCESS = 'immowa/auth/CANCEL_SUBSCRIPTION_SUCCESS';
const CANCEL_SUBSCRIPTION_FAIL = 'immowa/auth/CANCEL_SUBSCRIPTION_FAIL';

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function createCheckoutSession(user) {
  return {
    [CALL_API]: {
      types: [
        CREATE_CHECKOUT_SESSION_REQUEST,
        CREATE_CHECKOUT_SESSION_SUCCESS,
        CREATE_CHECKOUT_SESSION_FAIL
      ],
      method: 'POST',
      endpoint: '/stripe/create-checkout-session',
      // schema: Schemas.USER
      body: { user }
    }
  };
}
export function getPrices() {
  return {
    [CALL_API]: {
      types: [GET_PRICES_REQUEST, GET_PRICES_SUCCESS, GET_PRICES_FAIL],
      method: 'POST',
      endpoint: '/stripe/get-prices'
      // schema: Schemas.USER
      // body: { user }
    }
  };
}
export function cancelSubscription(user) {
  return {
    [CALL_API]: {
      types: [CANCEL_SUBSCRIPTION_REQUEST, CANCEL_SUBSCRIPTION_SUCCESS, CANCEL_SUBSCRIPTION_FAIL],
      method: 'POST',
      endpoint: '/stripe/cancel-subscription',
      // schema: Schemas.USER
      body: { user }
    }
  };
}
export function getPaymentIntent(user) {
  return {
    [CALL_API]: {
      types: [GET_PAYMENT_INTENT_REQUEST, GET_PAYMENT_INTENT_SUCCESS, GET_PAYMENT_INTENT_FAIL],
      method: 'POST',
      endpoint: '/stripe/get-payment-intent',
      // schema: Schemas.USER
      body: { user }
    }
  };
}
export function getSessionStripe(user) {
  return {
    [CALL_API]: {
      types: [GET_SESSION_STRIPE_REQUEST, GET_SESSION_STRIPE_SUCCESS, GET_SESSION_STRIPE_FAIL],
      method: 'POST',
      endpoint: '/stripe/get-session-stripe',
      // schema: Schemas.USER
      body: { user }
    }
  };
}
export function getSubscriptionStripe(user) {
  return {
    [CALL_API]: {
      types: [
        GET_SUBSCRIPTION_STRIPE_REQUEST,
        GET_SUBSCRIPTION_STRIPE_SUCCESS,
        GET_SUBSCRIPTION_STRIPE_FAIL
      ],
      method: 'POST',
      endpoint: '/stripe/get-subscription-stripe',
      // schema: Schemas.USER
      body: { user }
    }
  };
}
// export function getCustomerStripe(user) {
//   console.log('JPA user : ', user);
//   return {
//     [CALL_API]: {
//       types: [GET_CUSTOMER_STRIPE_REQUEST, GET_CUSTOMER_STRIPE_SUCCESS, GET_CUSTOMER_STRIPE_FAIL],
//       method: 'POST',
//       endpoint: '/stripe/get-customer-stripe',
//       // schema: Schemas.USER
//       body: { user }
//     }
//   };
// }
