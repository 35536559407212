import React, { useState, useEffect, Fragment } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// import "./App.css";

import { createCheckoutSession } from '../../store/modules/stripe';
import BaseLayout from '../shared/BaseLayout';
import RadioField from '../../components/RadioField';
import { STRIPE_PUBLIC_KEY } from '../../constants/Config';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CheckoutForm = ({ createCheckoutSession, prevStep, user }) => {
  const [clientSecretMensuel, setClientSecretMensuel] = useState('');
  const [clientSecretAnnuel, setClientSecretAnnuel] = useState('');
  const [loading, setLoading] = useState(true);
  const [paiement, setPaiement] = useState('1');
  useEffect(() => {
    setLoading(true);
    // Create a Checkout Session as soon as the page loads
    // on créé une session direct pour le mensuel et l'annuel, ensuite on affiche soit l'un soit l'autre

    createCheckoutSession({ ...user, paiement: '1' })
      .then(res => {
        return res.response;
      })
      .then(data => {
        setClientSecretMensuel(data.clientSecret);
        if (paiement === '1') {
          setLoading(false);
        }
      });
    createCheckoutSession({ ...user, paiement: '2' })
      .then(res => {
        return res.response;
      })
      .then(data => {
        setClientSecretAnnuel(data.clientSecret);
        if (paiement === '2') {
          setLoading(false);
        }
      });
  }, []);

  return (
    <Fragment>
      <div className="box">
        <div className="box-content">
          {!loading && (
            <div className="flexbox justifyCenter">
              <div className="formule-radios-list">
                <button
                  type="button"
                  onClick={() => setPaiement('1')}
                  className={`formule-radio ${paiement === '1' ? 'active' : ''}`}
                >
                  <span className="radio"></span>
                  <div className="formule-infos">
                    <div className="formule-title">Mensuel</div>
                    <div className="formule-price">
                      2,49&nbsp;€&nbsp;<span>par mois</span>
                    </div>
                  </div>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setPaiement('2');
                  }}
                  className={`formule-radio ${paiement === '2' ? 'active' : ''}`}
                >
                  <span className="radio"></span>
                  <div className="formule-infos">
                    <div className="formule-title">Annuel</div>
                    <div className="formule-price">
                      29&nbsp;€&nbsp;<span>par an</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          )}
          {/* <button type="button" onClick={() => submit()} className="btn btn-secondary">
            Valider
          </button> */}
          {paiement === '1' && clientSecretMensuel && (
            <Fragment>
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{ clientSecret: clientSecretMensuel }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </Fragment>
          )}
          {paiement === '2' && clientSecretAnnuel && (
            <Fragment>
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{ clientSecret: clientSecretAnnuel }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </Fragment>
          )}
        </div>
      </div>
      <div className="btn-group center">
        <button type="button" onClick={() => prevStep()} className="btn btn-secondary">
          <i className="fa-solid fa-arrow-left" />
          <span>Précédent</span>
        </button>
      </div>
    </Fragment>
  );
};

export default connect(null, { createCheckoutSession })(CheckoutForm);
