import React, { useEffect } from 'react';
import { connect } from 'react-redux';

export function requireAuthentication(Component) {
  const AuthenticatedComponent = props => {
    useEffect(() => {
      checkAuth();
    }, []);

    useEffect(() => {
      checkAuth();
    }, [props.location]);

    const checkAuth = () => {
      if (!props.user) {
        const redirectAfterLogin = props.location.pathname;
        props.history.push(`/auth`);
      }
    };
    return <div>{props.user ? <Component {...props} /> : null}</div>;
  };

  const mapStateToProps = state => ({
    user: state.auth.user
  });

  return connect(mapStateToProps)(AuthenticatedComponent);
}
