import { commodesActionsHandlers } from './commodes';
import { tiroirsActionsHandlers } from './tiroirs';
import { contenusActionsHandlers } from './contenus';
import { prochesActionsHandlers } from './proches';
import { settingsActionsHandlers } from './settings';
import { categoriesActionsHandlers } from './categories';
import { uploadsActionsHandlers } from './uploads';

import initialState from '../initialState';
import '../../index';

const actionsHandlers = {
  ...commodesActionsHandlers,
  ...tiroirsActionsHandlers,
  ...contenusActionsHandlers,
  ...prochesActionsHandlers,
  ...settingsActionsHandlers,
  ...categoriesActionsHandlers,
  ...uploadsActionsHandlers
};

export default function reducer(state = initialState, action) {
  const reduceFn = actionsHandlers[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
