import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useHistory, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import { register } from '../../store/modules/auth';

import BaseLayout from '../shared/BaseLayout';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

import MomentUtils from '@date-io/moment';

import InformationCreationClient from './InformationCreationClient';
import CheckoutForm from '../stripe/CheckoutForm';

const AbonnementUpdateSuccess = ({ register }) => {
  const history = useHistory();
  const location = useLocation();

  const confirmOk = () => {
    return history.push(`/abonnements/${location.search}`);
  };
  return (
    <BaseLayout isLogin classScreen="auth login">
      <div className="container">
        <div className="logo-block">
          <img src={require(`../../../assets/images/logo-immortaliz-vertical.svg`)} />
          <div className="title">Compte mis à jour !</div>
        </div>

        <div className="box">
          <div className="box-content">
            <p className="text-center">Votre abonnement a bien été mis à jour</p>
            <div className="btn-group center">
              <button onClick={() => confirmOk()} className="btn btn-primary">
                <span>OK</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

AbonnementUpdateSuccess.propTypes = {
  register: PropTypes.func.isRequired
};

export default connect(null, { register })(AbonnementUpdateSuccess);
