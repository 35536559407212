import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { find } from 'lodash';

const TiroirItem = ({ tiroir, user, special }) => {
  const droit =
    find(tiroir.proches, p => p.user_id === user.user_id) &&
    find(tiroir.proches, p => p.user_id === user.user_id).droits_acces;
  const isAuthorized = droit !== 'APRES_MON_DEPART';
  const isVisible = droit !== 'PAS_ACCES';

  return isVisible ? (
    isAuthorized ? (
      <Link
        disabled={!isAuthorized}
        className={`tiroir${special ? ' mailbox' : ''}`}
        to={{
          pathname: `/tiroir/${tiroir.tiroir_id}/${tiroir.value}`,
          state: { special }
        }}
      >
        <div className="tiroir-fond">
          <span />
          <span />
          <span />
        </div>
        <div className="tiroir-facade">
          {!special ? <span>{tiroir.nom}</span> : ''}
          {special ? (
            <div className="mailbox-door">
              <div className="mailbox-casquette" />
              <div className="mailbox-fente">
                <div className="mailbox-fente-shadow" />
              </div>
              <div className="mailbox-plaque">
                <div className="mailbox-title">Postez-moi un message</div>
                <div className="mailbox-icon">
                  <i className="fa-solid fa-paper-plane" />
                </div>
              </div>
            </div>
          ) : (
            <img src={require(`../../assets/images/tiroir-poignee.svg`)} />
          )}
        </div>
      </Link>
    ) : (
      <div className="tiroir lock">
        <div className="tiroir-fond">
          <span />
          <span />
          <span />
        </div>
        <div className="tiroir-facade">
          <span>{tiroir.nom}</span>
          <img className="tiroir-lock" src={require(`../../assets/images/tiroir-lock.svg`)} />
          <img src={require(`../../assets/images/tiroir-poignee-lock.svg`)} />
        </div>
      </div>
    )
  ) : null;
};

const mapStateToProps = state => ({
  user: state.auth.user
});

TiroirItem.propTypes = {
  user: PropTypes.object.isRequired,
  tiroir: PropTypes.object.isRequired
};

export default connect(mapStateToProps, null)(TiroirItem);
