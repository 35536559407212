import React, { useEffect } from 'react';

function ScriptObendy({ url }) {
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
    };

    loadScript();
  }, []);

  return <div></div>; // Return an empty div or any other component if needed.
}

export default ScriptObendy;
