import { find } from 'lodash';

export function getDroitTiroirUser(tiroirs, tiroirName, userId) {
  return find(find(tiroirs, t => t.value === tiroirName).proches, droit => droit.user_id === userId)
    .droits_acces;
}
export function renderDocIcon(fileType) {
  switch (fileType) {
    case 'pdf':
      return 'file-pdf';
    case 'microsoft_word':
      return 'file-word';
    case 'microsoft_excel':
      return 'file-excel';
    case 'microsoft_powerpoint':
      return 'file-powerpoint';
    case 'texte':
    case 'open_office':
      return 'fil';
  }
}
