import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';

const ButtonsSelector = ({ buttons, onClickButton, selected }) => {
  return (
    <Fragment>
      {map(
        buttons,
        (button, index) =>
          button && (
            <button
              onClick={() => onClickButton(button.value)}
              key={index}
              className={`tab ${selected === button.value ? 'active' : ''}`}
            >
              {button.label}
            </button>
          )
      )}
    </Fragment>
  );
};

ButtonsSelector.propTypes = {
  buttons: PropTypes.array.isRequired,
  onClickButton: PropTypes.func.isRequired
};
export default ButtonsSelector;
