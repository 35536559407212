import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { getTiroirs } from '../store/modules/tiroirs';
import { getCategories } from '../store/modules/categories';
import BaseLayout from './shared/BaseLayout';
import TiroirItem from '../components/TiroirItem';

const CommodeProchePage = ({ commodes, proches, getTiroirs, getCategories, tiroirs }) => {
  let { commodeId } = useParams();

  const proche = proches[commodes[commodeId].user_id];

  useEffect(() => {
    // 5389 charger les catégories propres a la commode
    getCategories(commodeId);
    getTiroirs(commodes[commodeId].user_id, commodeId);
  }, []);

  return (
    <BaseLayout classScreen="commode" isCommode proche={proche}>
      <div className="container justifyEvenly alignCenter">
        {map(tiroirs, tiroir => {
          return (
            <TiroirItem
              key={tiroir.tiroir_id}
              tiroir={tiroir}
              special={tiroir.value === 'postez-moi' ? true : false}
            />
          );
        })}
        <div className="btn-group center">
          <NavLink className="btn btn-primary" to={`/commode/${commodeId}/signaler-changement`}>
            Signaler un changement
          </NavLink>
        </div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  proches: state.data.entities.proches,
  commodes: state.data.entities.commodes,
  tiroirs: state.data.entities.tiroirs
});

CommodeProchePage.propTypes = {
  tiroirs: PropTypes.object.isRequired,
  commodes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  proches: PropTypes.object.isRequired,
  getTiroirs: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { getTiroirs, getCategories })(CommodeProchePage);
