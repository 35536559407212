import React from 'react';
import PropTypes from 'prop-types';

import { useHistory, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { desengagmentUser, signalementDeces } from '../store/modules/proches';

import BaseLayout from './shared/BaseLayout';
import ModalConfirm from '../components/modals/ModalConfirm';

const SignalerChangement = ({ user, commodes, proches, desengagmentUser, signalementDeces }) => {
  let { commodeId } = useParams();
  let history = useHistory();
  const parrain = proches[commodes[commodeId].user_id];

  return (
    <BaseLayout headerPageTitle="Signaler un changement">
      <div className="container justifyCenter">
        <div className="btn-group-signalement">
          <ModalConfirm
            confirmMethode={() => {
              signalementDeces(user, parrain).then(() => history.push('/'));
            }}
            textButton="Signaler le décès à Immortaliz"
            text="Confirmez-vous l'envoi du signalement du décès à Immortaliz ?"
          />
          <ModalConfirm
            confirmMethode={() => {
              desengagmentUser(user, parrain, commodeId).then(() => history.push('/'));
            }}
            textButton="Je ne veux plus accéder à cette commode"
            text="Confirmez-vous la suppression de l'accès à cette commode ?"
          />
        </div>
      </div>
    </BaseLayout>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  commodes: state.data.entities.commodes,
  proches: state.data.entities.proches
});

SignalerChangement.propTypes = {
  user: PropTypes.object.isRequired,
  commodes: PropTypes.object.isRequired,
  desengagmentUser: PropTypes.func.isRequired,
  signalementDeces: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { desengagmentUser, signalementDeces })(SignalerChangement);
