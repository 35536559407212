import React from 'react';

import BaseLayout from './shared/BaseLayout';

const MentionsLegalesPage = () => {
  return (
    <BaseLayout headerPageTitle="Mentions légales" isBack>
      <div className="container alignCenter">
        <p>Mentions legales text</p>
      </div>
    </BaseLayout>
  );
};

export default MentionsLegalesPage;
