export const ACCES_TIROIRS = {
  PAS_ACCES: 'Aucun accès',
  DE_MON_VIVANT: 'Accès à partir de maintenant',
  APRES_MON_DEPART: 'Accès seulement après mon départ'
};

export const NOMS_TIROIRS = {
  'postez-moi': { value: 'Postez-moi un message' },
  'ma-vie': { value: "L'album de ma vie", id: 1 },
  'a-partager': { value: 'Ce que je veux partager', id: 2 },
  'mon-depart': { value: 'Mon départ', id: 5 },
  administratif: { value: 'Mes documents administratifs', id: 4 },
  'mes-directives': { value: "Mes souhaits en cas d'urgence", id: 3 }
};

export const ICON_FILE = {
  img: 'fal fa-image',
  document: 'fal fa-file-alt',
  video: 'fal fa-camera',
  audio: 'fal fa-microphone-alt'
};
