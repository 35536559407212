import React, { useState, Fragment } from 'react';

import { useDropzone } from 'react-dropzone';
import { includes, map, filter, isEmpty, union, cloneDeep } from 'lodash';

import ReactPlayer from 'react-player/lazy';

import { API_URL } from '../../constants/Config';

import { getFileType } from '../../utils/StringUtils';

import { renderDocIcon } from '../../utils/methodes';

const FieldUploadDropZone = props => {
  const [files, setFiles] = useState((props.initFiles && props.initFiles) || []);

  function fileSizeValidator(file) {
    if (file.size > 512000000) {
      return {
        code: 'file-too-large',
        message: `Votre fichier ${file.path} ne doit pas dépasser la taille de 512Mo`
      };
    }

    return null;
  }

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: props.acceptedFiles,
    validator: fileSizeValidator,
    multiple: true,
    noDrag: true,
    onDrop: acceptedFiles => {
      const dataFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );

      map(dataFiles, f => (f.fileType = getFileType(f.name)));

      if (isEmpty(files)) {
        setFiles(dataFiles);

        if (props.onChange) {
          props.onChange(dataFiles);
        }
      } else {
        setFiles(union(files, dataFiles));
        if (props.onChange) {
          props.onChange(union(files, dataFiles));
        }
      }
    }
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) =>
    errors.map(e => (
      <div key={e.code} className="field-error">
        {e.message}
      </div>
    ))
  );

  const removeFile = (file, index) => {
    let newFiles = cloneDeep(files);
    newFiles.splice(index, 1);
    props.onChange(newFiles);
    setFiles(newFiles);
  };

  return (
    <Fragment>
      {!props.isSingleFile ? (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <i className="fal fa-cloud-upload" />
          <div>
            Glisser et déposer votre fichier ici ou <span>Choisir un fichier</span>
          </div>
          {fileRejectionItems}
        </div>
      ) : (
        isEmpty(files) && (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <i className="fal fa-cloud-upload" />
            <div>
              Glisser et déposer votre fichier ici ou <span>Choisir un fichier</span>
            </div>
            {fileRejectionItems}
          </div>
        )
      )}

      <div className="uploaded-file-block">
        <div className="grid">
          {map(files, (file, index) => (
            <div className={`col-4_xs-12_sm-6_md-4 file-${file.fileType}`} key={index}>
              <div className={`uploaded-file`}>
                {file.fileType === 'image' && (
                  <img
                    src={
                      file.preview
                        ? file.preview
                        : `${API_URL}/uploads/${props.userId}/${file.fileName}`
                    }
                    alt="preview"
                  />
                )}
                {includes(
                  [
                    'microsoft_word',
                    'microsoft_excel',
                    'microsoft_powerpoint',
                    'open_office',
                    'texte',
                    'pdf'
                  ],
                  file.fileType
                ) && (
                  <Fragment>
                    <i className={`fas fa-${renderDocIcon(file.fileType)}`} />
                    <span>
                      {file.path
                        ? file.path
                        : file.fileName.substring(file.fileName.indexOf('-') + 8)}
                    </span>
                  </Fragment>
                )}
                {file.fileType === 'audio' && (
                  <figure>
                    <audio
                      type="audio/mp3"
                      controls
                      src={
                        file.preview
                          ? file.preview
                          : `${API_URL}/uploads/${props.userId}/${file.fileName}`
                      }
                    />
                  </figure>
                )}
                {file.fileType === 'video' && (
                  <div className="player-wrapper">
                    <ReactPlayer
                      key={
                        file.preview
                          ? file.preview
                          : `${API_URL}/uploads/${props.userId}/${file.fileName}`
                      }
                      controls
                      className="react-player"
                      type="video/mp4"
                      url={
                        file.preview
                          ? file.preview
                          : `${API_URL}/uploads/${props.userId}/min_${file.fileName}`
                      }
                    />
                  </div>
                )}
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    return Promise.resolve().then(() => {
                      if (file.preview) {
                        return removeFile(file, index);
                      } else {
                        props.prepareDeleteFileContenu(file);
                        return setFiles(filter(files, f => f.fileName !== file.fileName));
                      }
                    });
                  }}
                >
                  <i className="fas fa-trash-alt" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default FieldUploadDropZone;
