import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import HeaderPage from '../../components/shared/HeaderPage';
import ScriptObendy from './ScriptObendy';
Modal.setAppElement('body');

const BaseLayout = ({
  children,
  headerPageTitle,
  isCommode,
  isBack,
  backUrl,
  isHome,
  isLogin,
  isProfil,
  proche,
  classScreen,
  account
}) => {
  // Fix min/max height for webkit mobile browser
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  return (
    <div id="wrapper" className={classScreen}>
      {process.env.NODE_ENV === 'staging' && (
        <div id="staging-banner">
          <i className="fas fa-exclamation-triangle" />
          Environnement de recette
          <i className="fas fa-exclamation-triangle" />
        </div>
      )}
      <HeaderPage
        isBack={isBack}
        backUrl={backUrl}
        isCommode={isCommode}
        isHome={isHome}
        isLogin={isLogin}
        isProfil={isProfil}
        proche={proche}
        title={headerPageTitle}
        account={account}
      />

      <main>{children}</main>
      <div className="bkg-overlay">
        {/* <div className="bkg" /> */}
        <img src={require(`../../../assets/images/immortaliz-empreinte.svg`)} />
      </div>

      {account && account.pixel_analytics_url && <ScriptObendy url={account.pixel_analytics_url} />}
      {account && account.widget_url && <ScriptObendy url={account.widget_url} />}

      {/* // <div
      // dangerouslySetInnerHTML={{
      //   __html: appHabitants.inscription.inscriptionCGU
      // }}
      // >{}</div>} */}
    </div>
  );
};
BaseLayout.propTypes = {
  children: PropTypes.node
};

export default connect(state => ({ account: state.auth.user }))(BaseLayout);
