import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createCategorie, removeCategorie } from '../../store/modules/categories';

import Modal from 'react-modal';

import { Form, Field } from 'react-final-form';

import ClipLoader from 'react-spinners/ClipLoader';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';

const ModalAddCategorie = ({
  categorie,
  categories,
  removeCategorie,
  contenus,
  setRemoveCategorie,
  setSelectedFilter
}) => {
  const [loading, setLoading] = useState(false);
  const { tiroirName, tiroirId } = useParams();
  function sendRemoveCategorie() {
    setLoading(true);
    return removeCategorie(categories[tiroirName], categorie)
      .then(res => {
        setSelectedFilter(1);
        setRemoveCategorie(false);
        setLoading(false);
      })
      .catch(err => {
        setSelectedFilter(1);
        console.log('error : ', err);
        setLoading(false);
      });
  }
  return (
    <Fragment>
      {removeCategorie && (
        <Modal
          className="modal-content"
          overlayClassName="modal-overlay modal-classic"
          id="modal-categorie"
          isOpen={removeCategorie}
        >
          <div>
            <div className="modal-title">
              Supprimer la catégorie
              <div
                onClick={() => {
                  setRemoveCategorie();
                }}
                className="btn-close"
              >
                <i className="far fa-times" />
              </div>
            </div>
            <div className="modal-body">
              {isEmpty(contenus) ? (
                <div class="center">
                  Êtes-vous sur de vouloir supprimer la catégorie {categorie && categorie.label} ?
                </div>
              ) : (
                <div>Suppression impossible, des contenus sont rattachés à cette catégorie </div>
              )}
              <div className="btn-group center">
                <button
                  onClick={() => setRemoveCategorie()}
                  className="btn btn-secondary"
                  disabled={loading}
                >
                  <span>Annuler</span>
                </button>
                {isEmpty(contenus) && (
                  <button
                    onClick={() => sendRemoveCategorie()}
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    <span>
                      {loading ? (
                        <ClipLoader size={15} color={'white'} loading={loading} />
                      ) : (
                        'Supprimer'
                      )}
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

ModalAddCategorie.propTypes = {
  createCategorie: PropTypes.func.isRequired,
  removeCategorie: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    tiroirs: state.data.entities.tiroirs,
    categories: state.data.entities.categories,
    user: state.auth.user
  };
};
export default connect(mapStateToProps, {
  createCategorie,
  removeCategorie
})(ModalAddCategorie);
