import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { version } from '../../../package.json';

import { NavLink, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { login } from '../../store/modules/auth';

import { Form, Field } from 'react-final-form';
import { validateEmail } from '../../utils/StringValidator';

import BaseLayout from '../shared/BaseLayout';

const LoginPage = ({ login }) => {
  const history = useHistory();
  const [displayPassword, setDisplayPassword] = useState(false);
  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');
  const requiredEmail = value =>
    value ? (validateEmail(value) ? undefined : 'Email incorrect') : 'Champ requis !';

  const handleSubmit = values => {
    return login(values.email, values.password)
      .then(res => {
        return history.push('/');
      })
      .catch(err => setError(err));
  };

  return (
    <BaseLayout isLogin classScreen="login">
      <div className="container">
        <img src={require(`../../../assets/images/immortaliz-commode.svg`)} />
        <div className="title">Connectez-vous</div>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field name="email" validate={requiredEmail} component="input" className="field">
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <input
                      {...input}
                      autoComplete="email"
                      type="email"
                      placeholder="Adresse email *"
                      autoCapitalize="none"
                    />
                  </div>
                )}
              </Field>

              <Field validate={required} name="password" component="input" className="field">
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <input
                      {...input}
                      autoComplete="current-password"
                      type={(displayPassword && 'text') || 'password'}
                      onChange={value => {
                        setError(null);
                        input.onChange(value);
                      }}
                      placeholder="Mot de passe *"
                    />
                    {displayPassword ? (
                      <span className="clear-password" onClick={() => setDisplayPassword(false)}>
                        <i className="fas fa-eye-slash" />
                      </span>
                    ) : (
                      <span className="clear-password" onClick={() => setDisplayPassword(true)}>
                        <i className="fas fa-eye" />
                      </span>
                    )}
                  </div>
                )}
              </Field>

              {error && (
                <div className="error-block">
                  <span className="error-icon">
                    <i className="fas fa-exclamation" />
                  </span>
                  <div className="error-message">{error}</div>
                </div>
              )}
              <div className="auth-buttons">
                <button type="submit" className="btn btn-primary">
                  <span>Je me connecte</span>
                </button>

                <NavLink className="btn-link  btn-link-secondary" to="/mot-de-passe-oubliee">
                  J'ai oublié mon mot de passe
                </NavLink>
                <NavLink className="btn-link" to="/inscription">
                  Je n'ai pas encore de compte
                </NavLink>
              </div>
            </form>
          )}
        />
      </div>
    </BaseLayout>
  );
};

LoginPage.propTypes = {
  login: PropTypes.func.isRequired
};

export default connect(null, { login })(LoginPage);
