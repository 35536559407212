import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import modalDefaultClass from '../../utils/ModalDefaultClass';

const ModalConfirm = ({ confirmMethode, title, textButton, confirmText, text, className, img }) => {
  const [isOpen, setIsOpen] = useState(false);

  const confirm = () => {
    confirmMethode();
    setIsOpen(false);
  };

  return (
    <Fragment>
      <button
        onClick={() => setIsOpen(true)}
        className={`${(className && className) || 'btn btn-primary'} `}
      >
        {img}
        <span>{textButton}</span>
      </button>
      {isOpen && (
        <Modal {...modalDefaultClass} id="modal-confirm" isOpen={isOpen}>
          <div className="modal-title">
            {(title && title) || 'Confirmation'}
            <div onClick={() => setIsOpen(false)} className="btn-close">
              <i className="far fa-times" />
            </div>
          </div>
          <div className="modal-body">
            {text}
            <div className="btn-group center">
              <button onClick={() => setIsOpen(false)} className="btn btn-secondary">
                <span>Annuler</span>
              </button>
              <button onClick={() => confirm()} className="btn btn-primary">
                <span>{(confirmText && confirmText) || 'Confirmer'}</span>
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

ModalConfirm.propTypes = {
  confirmMethode: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default ModalConfirm;
