import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { find } from 'lodash';

import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';

import { connect } from 'react-redux';
import { getPrices } from '../../store/modules/stripe';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

import MomentUtils from '@date-io/moment';
import RadioField from '../../components/RadioField';

const SelectPack = ({
  nextStep,
  prevStep,
  selectedPack,
  setSelectedPack,
  isInscription,
  getPrices
}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState([]);
  useEffect(() => {
    setLoading(true);
    // Create a Checkout Session as soon as the page loads
    getPrices().then(res => {
      setPrices(res.response);
      setLoading(false);
      return res.response;
    });
    // .then(data => setClientSecret(data.clientSecret));
  }, []);

  return (
    <div className="flexbox flexColumn flex-1 full-width">
      <div className="flex-1">
        <div className="grid-2-center">
          <div className="col">
            <button
              onClick={() => {
                return setSelectedPack(2);
              }}
              type="button"
              className={`box box-plan highlight ${selectedPack === 2 ? 'selected' : ''}`}
            >
              <div className="box-content">
                <span className="box-plan-radio">
                  <i className="fa-solid fa-check" />
                </span>
                <div className="box-plan-header">
                  <div className="box-plan-title">Pack Premium</div>
                  {!loading &&
                    find(prices, p => {
                      return p.recurring.interval === 'year';
                    }) && (
                      <div className="box-plan-price">
                        {find(prices, p => p.recurring.interval === 'year').unit_amount / 100} €/an
                      </div>
                    )}
                  {!loading &&
                    find(prices, p => {
                      return p.recurring.interval === 'month';
                    }) && (
                      <div className="">
                        ou {find(prices, p => p.recurring.interval === 'month').unit_amount / 100}{' '}
                        €/mois
                      </div>
                    )}
                </div>
                <div className="box-plan-args-list">
                  <div className="box-plan-arg">
                    <i className="fa-solid fa-check-circle" />1 commode
                  </div>
                  <div className="box-plan-arg">
                    <i className="fa-solid fa-check-circle" />6 proches désignés
                  </div>
                  <div className="box-plan-arg">
                    <i className="fa-solid fa-check-circle" />
                    Stockage 10 Go
                  </div>
                </div>
              </div>
            </button>
          </div>
          {isInscription ? (
            <div className="col">
              <button
                onClick={() => {
                  return setSelectedPack(1);
                }}
                type="button"
                className={`box box-plan ${selectedPack === 1 ? 'selected' : ''}`}
              >
                <div className="box-content">
                  <span className="box-plan-radio">
                    <i className="fa-solid fa-check" />
                  </span>
                  <div className="box-plan-header">
                    <div className="box-plan-title">Pack découverte</div>
                    <div className="box-plan-price">Gratuit</div>
                  </div>
                  <div className="box-plan-args-list">
                    <div className="box-plan-arg">
                      <i className="fa-solid fa-check-circle" />1 commode
                    </div>
                    <div className="box-plan-arg">
                      <i className="fa-solid fa-check-circle" />1 proche désigné
                    </div>
                    <div className="box-plan-arg">
                      <i className="fa-solid fa-check-circle" />
                      Stockage 100 Mo
                    </div>
                  </div>
                </div>
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className="btn-group center">
        <button
          onClick={() => {
            return history.goBack();
          }}
          type="button"
          className="btn btn-secondary"
        >
          <i className="fa-solid fa-arrow-left" />
          <span>Précédent</span>
        </button>
        <button disabled={!selectedPack} onClick={nextStep} className="btn btn-primary">
          <span>Suivant</span>
          <i className="fa-solid fa-arrow-right" />
        </button>
      </div>
    </div>
  );
};

export default connect(null, { getPrices })(SelectPack);
