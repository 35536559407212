import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { NavLink, useHistory, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import { createPassword, login } from '../store/modules/auth';

import BaseLayout from './shared/BaseLayout';

import { Form, Field } from 'react-final-form';
import { toLower } from 'lodash';

const DefinirPasswordPage = ({ createPassword, login }) => {
  const navigation = useHistory();
  const { email, userid, token } = useParams();
  const [displayPassword, setDisplayPassword] = useState(false);
  const [displayPassword2, setDisplayPassword2] = useState(false);

  const [error, setError] = useState(null);

  const required = value => (value ? undefined : 'Champ requis !');

  const handleSubmit = values => {
    const data = values;
    data.authorization_token = token;
    data.reset_token = token;
    data.userId = Number(userid);

    if (data.password === data.password_repeat) {
      return createPassword(data)
        .then(() => {
          setError(null);
        })
        .then(() => {
          return login(email, data.password);
        })
        .then(() => {
          navigation.push('/');
        })
        .catch(err => setError(err));
    } else {
      setError("Votre mot de passe n'est pas identique !");
    }
  };
  return (
    <BaseLayout isLogin classScreen="login">
      <div className="container">
        <div className="title">Définir mon mot de passe</div>
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                component="input"
                className="field"
                initialValue={(email && toLower(email)) || ' '}
              >
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <input {...input} type="email" autoCapitalize="none" disabled={true} />
                    <label>Adresse e-mail *</label>
                  </div>
                )}
              </Field>

              <Field validate={required} name="password" component="input" className="field">
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <input
                      {...input}
                      autoComplete="current-password"
                      type={(displayPassword && 'text') || 'password'}
                      onChange={value => {
                        setError(null);
                        input.onChange(value);
                      }}
                    />
                    <label>Mot de passe *</label>
                    {displayPassword ? (
                      <span className="clear-password" onClick={() => setDisplayPassword(false)}>
                        <i className="fas fa-eye-slash" />
                      </span>
                    ) : (
                      <span className="clear-password" onClick={() => setDisplayPassword(true)}>
                        <i className="fas fa-eye" />
                      </span>
                    )}
                  </div>
                )}
              </Field>
              <Field validate={required} name="password_repeat" component="input" className="field">
                {({ input, meta }) => (
                  <div className="field">
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    <input
                      {...input}
                      type={(displayPassword2 && 'text') || 'password'}
                      autoComplete="current-password"
                      onChange={value => {
                        setError(null);
                        input.onChange(value);
                      }}
                    />
                    <label>Confirmer votre mot de passe *</label>
                    {displayPassword2 ? (
                      <span className="clear-password" onClick={() => setDisplayPassword2(false)}>
                        <i className="fas fa-eye-slash" />
                      </span>
                    ) : (
                      <span className="clear-password" onClick={() => setDisplayPassword2(true)}>
                        <i className="fas fa-eye" />
                      </span>
                    )}
                  </div>
                )}
              </Field>
              {error && <div className="error-block">{error}</div>}
              <div className="auth-buttons">
                <button type="submit" className="btn btn-primary">
                  <span>Valider</span>
                </button>

                <NavLink className="btn-link btn-link-secondary" to="/connexion">
                  Revenir à la page de connexion
                </NavLink>
              </div>
            </form>
          )}
        />
      </div>
    </BaseLayout>
  );
};

DefinirPasswordPage.propTypes = {
  createPassword: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired
};

export default connect(null, { createPassword, login })(DefinirPasswordPage);
