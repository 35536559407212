import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';

import BaseLayout from '../shared/BaseLayout';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

import MomentUtils from '@date-io/moment';

import InformationCreationClient from './InformationCreationClient';
import CheckoutForm from '../stripe/CheckoutForm';

const InscriptionSuccess = ({}) => {
  const history = useHistory();

  const confirmOk = () => {
    return history.push('connexion');
  };
  return (
    <BaseLayout isLogin classScreen="auth login">
      <div className="container">
        <div className="logo-block">
          <img src={require(`../../../assets/images/logo-immortaliz-vertical.svg`)} />
          <div className="title">Compte validé !</div>
        </div>

        <div className="box">
          <div className="box-content">
            <p className="text-center">
              Votre compte Immortaliz a été créé avec succès, vous allez recevoir un e-mail vous
              permettant de définir votre mot de passe pour vous permettre de vous connecter.
            </p>
            <div className="btn-group center">
              <button onClick={() => confirmOk()} className="btn btn-primary">
                <span>OK</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

InscriptionSuccess.propTypes = {
  register: PropTypes.func.isRequired
};

export default connect(null, {})(InscriptionSuccess);
