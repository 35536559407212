import { schema } from 'normalizr';
const userSchema = new schema.Entity('user', {}, { idAttribute: 'user_id' });
const commodeSchema = new schema.Entity('commodes', {}, { idAttribute: 'commode_id' });
const tiroirSchema = new schema.Entity('tiroirs', {}, { idAttribute: 'tiroir_id' });
const categorieSchema = new schema.Entity('categories', {}, { idAttribute: '_id' });
const procheSchema = new schema.Entity('proches', {}, { idAttribute: 'user_id' });
const contenuSchema = new schema.Entity('contenus', {}, { idAttribute: 'contenu_id' });

const settingSchema = new schema.Entity('settings', {}, { idAttribute: 'type' });

export const Schemas = {
  USER: userSchema,
  COMMODE: commodeSchema,
  COMMODE_ARRAY: new schema.Array(commodeSchema),
  TIROIR: tiroirSchema,
  TIROIR_ARRAY: new schema.Array(tiroirSchema),

  CATEGORIE: categorieSchema,
  CATEGORIE_ARRAY: new schema.Array(categorieSchema),
  PROCHE: procheSchema,
  PROCHE_ARRAY: new schema.Array(procheSchema),
  CONTENU: contenuSchema,
  CONTENU_ARRAY: new schema.Array(contenuSchema),
  COMMODE_TIROIR_ARRAY: new schema.Array(
    {
      commodes: commodeSchema,
      tiroirs: new schema.Array(tiroirSchema)
    },
    input => {
      return input.type;
    }
  ),
  SETTING: settingSchema,
  SETTING_ARRAY: new schema.Array(settingSchema)
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
